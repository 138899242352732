/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from 'components/Logo';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { useForm } from 'react-hook-form';
import ButtonLoading from 'components/Button/ButtonLoading';
import { useSnackbar } from 'notistack';
import Copyright from 'components/Copyright';
import FormInputPassword from 'components/FormInputPassword';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  typography: {
    marginTop: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  senha: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  confirmarSenha: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .oneOf(
      [yup.ref('senha'), ''],
      ConstanteMensagemValidacao.SENHA_NAO_COINCIDEM
    ),
});

const RedefinirSenha: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  let nome = query.get('nome');
  nome = decodeURIComponent(nome as string);

  let email = query.get('email');
  email = decodeURIComponent(email as string);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    let token = query.get('token');

    token = decodeURIComponent(token as string);

    const dataPut = {
      token,
      email,
      novaSenha: data.senha,
    };

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.AUTENTICACAO_ALTERAR_SENHA,
      dataPut
    );

    setLoading(false);

    if (response.sucesso) {
      enqueueSnackbar(
        'Senha redefinida com sucesso. Agora você já pode acessar o sistema com a nova senha.',
        {
          variant: 'success',
        }
      );
      history.push(ConstanteRotas.LOGIN);
      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Logo />
        <Typography variant="h6" className={classes.typography}>
          Olá <b>{nome}</b>, cadastre uma nova senha!
        </Typography>
        <form onSubmit={onSubmit} className={classes.form}>
          <FormInputPassword
            required
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nova senha"
            name="senha"
            autoFocus
            control={control}
            error={Boolean(errors.senha)}
            messageError={errors.senha?.message}
            autoComplete="new-password"
            labelWidth={93}
          />

          <FormInputPassword
            required
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            label="Confirmar nova senha"
            name="confirmarSenha"
            control={control}
            error={Boolean(errors.confirmarSenha)}
            messageError={errors.confirmarSenha?.message}
            autoComplete="new-password"
            labelWidth={166}
          />

          <ButtonLoading
            text="Redefinir senha"
            loading={loading}
            className={classes.submit}
          />
          <Grid container>
            <Grid item lg>
              <Link
                onClick={() => history.push(ConstanteRotas.LOGIN)}
                variant="body2"
                color="secondary"
                style={{ cursor: 'pointer' }}
              >
                Voltar para o login!
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default RedefinirSenha;
