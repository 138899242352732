import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import GraficoSemDados from 'components/GraficoSemDados';
import LegendaGrafico from 'components/LegendaGrafico';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';
import DatePickerRange from 'components/DatePickerRange';
import dateToDayDatePicker from 'helpers/dateFormat';
import { DayRange } from 'react-modern-calendar-datepicker';
import extractInformationChartV2 from 'helpers/extractInformationChartV2';

const VendaLojaPorFormaPagamento = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.Vendas_VendasPorFormaPagto
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const atualizarGrafico = (list, total) => {
    const colors = [
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#03A9F4',
      '#4CAF50',
      '#F9CE1D',
      '#FF9800',
      '#33B2DF',
      '#c9509b',
    ];

    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    const listSort = listPorcentagem.sort(
      (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
    );

    setLegenda(
      listSort.map((item, index) => ({
        cor: colors[index <= colors.length - 1 ? index : index % colors.length],
        formaPagto: item.nome,
        valor: item.valor,
        porcentagem: `${item.porcentagem.toFixed(1)}%`,
      }))
    );

    const infoGrafico = {
      series: listSort.map((item) => item.porcentagem),

      options: {
        stroke: {
          lineCap: 'round',
        },
        chart: {
          id: 'vendas-por-forma-pagamento',
        },
        colors,
        labels: listSort.map((item) => item.nome),
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          labels: {
            colors: '#fff',
            useSeriesColors: false,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => `${val.toFixed(1)}%`,
          },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: listSort.map((item) => item.porcentagem),
      options: {
        ...infoGrafico.options,
        labels: listSort.map((item) => item.nome),
      },
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec(newInfoGrafico.options.chart.id, 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      setInfoGraficoState(undefined);

      const informacao = obterDadosGrafico(chartData);

      const { valTotal, listItens } = extractInformationChartV2(
        informacao,
        dtInicial,
        dtFinal
      );

      setTotalVendas(valTotal);
      atualizarGrafico(listItens, valTotal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const resetGrafico = () => {
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);

      setDataUltimaSincronizacao(dateTo.toLocaleString());

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Data,
          valor: item.Data,
          possuiDados: item.Total > 0,
          key: index,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
  };

  return (
    <>
      <HeaderCard
        titulo="Por Formas de Pagamento"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />

      <DatePickerRange
        bgButtonColor="#4B5360"
        bgDateColor="#323B40"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total das Vendas"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            <ReactApexChart
              options={infoGraficoState.options}
              series={infoGraficoState.series}
              type="pie"
              height="300px"
            />
            {legenda.map((item) => (
              <LegendaGrafico
                key={item.formaPagto}
                cor={item.cor}
                descricao={item.formaPagto}
                valor={formatarDinheiro(item.valor, true)}
                porcentagem={item.porcentagem}
                corFundo="#4B5360"
                corPrincipal="#323B40"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default VendaLojaPorFormaPagamento;
