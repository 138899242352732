import React from 'react';

import logo from 'assets/images/sti3-icon.png';
import auth from 'auth';
import TipoSistemaEnum from 'constants/enumTipoSistema';

import { ZendarCompleto, PowerStockLogotipo } from 'icons';

const Logo: React.FC = () => {
  const sistema = auth.getSistema() || '';

  switch (sistema.value) {
    case TipoSistemaEnum.ZENDAR:
      return (
        <ZendarCompleto
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '36px',
            marginBottom: '1rem',
          }}
        />
      );

    case TipoSistemaEnum.POWERSTOCK:
      return (
        <PowerStockLogotipo
          style={{
            alignSelf: 'center',
            width: '200px',
            height: '36px',
            marginBottom: '1rem',
          }}
        />
      );

    default:
      return (
        <img
          src={logo}
          alt="logo"
          style={{ width: '204px', height: '67.5px', marginBottom: '1rem' }}
        />
      );
  }
};

export default Logo;
