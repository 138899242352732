import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Chip,
  colors,
  Fab,
  Popover,
  Zoom,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Add, ErrorOutline } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { UsuariosIcon } from 'icons';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoSistemaEnum from 'constants/enumTipoSistema';
import auth from 'auth';
import api, { ResponseApi } from 'services/api';

import DialogConfirm from 'components/DialogConfirm';
import Transition from 'components/TransitionDefault';
import ButtonMoreOption from 'components/GerenciarUsuario/ButtonMoreOption';
import Loading from 'components/Loading';

import FiltroUsuario from './FiltroUsuario';
import InserirUsuario from './Inserir';
import AlterarUsuario from './Alterar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      background: colors.grey[800],
      height: '100%',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    helpMessage: {
      background: colors.grey[700],
    },
    typographyHelp: {
      padding: theme.spacing(2),
    },
  })
);

export interface UsuarioInterface {
  id: string;
  nome: string;
  email: string;
  administrador: string;
  emailConfirmed: boolean;
  lojas: Array<string>;
}

const GerenciarUsuario = ({ ...props }) => {
  const { classesItem, currentPage } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showAdicionar, setShowAdicionar] = useState(true);
  const [showAdicionarDialog, setShowAdicionarDialog] = useState(false);
  const [showAlterarDialog, setShowAlterarDialog] = useState(false);
  const [usuarioId, setUsuarioId] = useState('');
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const [usuarios, setUsuarios] = useState([] as Array<UsuarioInterface>);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState(
    [] as Array<UsuarioInterface>
  );
  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
  const [
    dialogConfirmOpenAlterarSenha,
    setDialogConfirmOpenAlterarSenha,
  ] = useState(false);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<
    UsuarioInterface
  >({} as UsuarioInterface);

  const sistema = auth.getSistema();
  const sistemaIsSti3 = sistema.value === TipoSistemaEnum.STI3;

  const { enqueueSnackbar } = useSnackbar();

  const { control, reset, getValues } = useForm({
    defaultValues: {
      loja: '',
      nome: '',
      email: '',
    },
  });

  const filtrarUsuarios = () => {
    const { loja, nome, email } = getValues();

    const listaFiltrada = usuarios.filter((usuarioItem) => {
      return (
        (nome === '' ||
          usuarioItem.nome.toLowerCase().includes(nome.toLowerCase())) &&
        (email === '' ||
          usuarioItem.email.toLowerCase().includes(email.toLowerCase())) &&
        (loja === '' ||
          loja === undefined ||
          usuarioItem.lojas.includes(loja.toLowerCase()))
      );
    });

    setUsuariosFiltrados(listaFiltrada);
  };

  const getUsuarios = useCallback(async () => {
    setLoading(true);
    const response = await api.get<void, ResponseApi<Array<UsuarioInterface>>>(
      ConstanteEnderecoWebservice.USUARIO_LISTAR
    );

    setLoading(false);
    if (response.sucesso) {
      setUsuarios(response.dados);
      setUsuariosFiltrados(response.dados);
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }
  }, [enqueueSnackbar]);

  const handleClickOpen = () => {
    getUsuarios();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = async () => {
    setLoading(true);
    setDialogConfirmOpen(false);
    const response = await api.delete<void, ResponseApi>(
      ConstanteEnderecoWebservice.USUARIO_EXCLUIR,
      {
        params: {
          id: usuarioSelecionado.id,
        },
      }
    );

    if (response.sucesso) {
      getUsuarios();
      enqueueSnackbar('Usuário excluído com sucesso.', { variant: 'success' });
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }

    setLoading(false);
  };

  const confirmAlterarSenha = async () => {
    setLoading(true);
    setDialogConfirmOpenAlterarSenha(false);

    const response = await api.post<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.AUTENTICACAO_RECUPERAR_SENHA}${usuarioSelecionado.email}`
    );

    if (response.sucesso) {
      enqueueSnackbar('E-mail para alteração de senha enviado com sucesso.', {
        variant: 'success',
      });
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }

    setLoading(false);
  };

  const handleScroll = (scrollTop) => {
    setShowAdicionar(scrollTop <= scrollPosition);
    setScrollPosition(scrollTop);
  };

  const handleAlterar = (id) => {
    setUsuarioId(id);
    setShowAlterarDialog(true);
  };

  const handleExcluir = (usuario) => {
    setUsuarioSelecionado(usuario);
    setDialogConfirmOpen(true);
  };

  const handleResetSenha = (usuario) => {
    setUsuarioSelecionado(usuario);
    setDialogConfirmOpenAlterarSenha(true);
  };

  const handleReenviarEmailConfirmacao = async (usuario) => {
    setLoading(true);

    const response = await api.post<void, ResponseApi>(
      `${ConstanteEnderecoWebservice.USUARIO_REENVIAR_CONFIRMACAO_EMAIL}${usuario.email}`,
      null
    );

    if (response.sucesso) {
      enqueueSnackbar(
        `O e-mail para confirmação foi enviado com sucesso para '${usuario.email}'.`,
        {
          variant: 'success',
        }
      );
    }

    if (response.avisos) {
      response.avisos.forEach((aviso: string) =>
        enqueueSnackbar(aviso, { variant: 'warning' })
      );
    }

    setLoading(false);
  };

  const openHelp = Boolean(anchorElHelp);

  const handleClickOpenHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClickCloseHelp = () => {
    setAnchorElHelp(null);
  };

  return (
    <>
      {sistemaIsSti3 && (
        <ListItem
          key="gerenciar-usuarios"
          className={classesItem.item}
          disableGutters
          selected={currentPage === 'gerenciar-usuarios'}
        >
          <Button
            className={classesItem.button}
            onClick={handleClickOpen}
            color="secondary"
          >
            <UsuariosIcon className={classesItem.icon} />
            <span className={classesItem.title}>Gerenciar usuários</span>
          </Button>
        </ListItem>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.body}
      >
        {loading && <Loading />}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Gerenciar usuários
            </Typography>
            <FiltroUsuario
              control={control}
              reset={reset}
              actionHandle={filtrarUsuarios}
              getValues={getValues}
            />
          </Toolbar>
        </AppBar>

        <Divider style={{ background: colors.grey[700] }} />

        <List
          onScroll={(e: any) => {
            handleScroll(e.target.scrollTop);
          }}
          className={classes.body}
          style={{ overflow: 'auto' }}
        >
          {usuariosFiltrados.map((usuarioItem) => (
            <React.Fragment key={usuarioItem.id}>
              <ListItem>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'inline-grid',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ fontSize: '18px' }}>
                        {usuarioItem.nome}
                      </Typography>
                      {usuarioItem.administrador && (
                        <Chip
                          size="small"
                          label="Admin"
                          style={{
                            marginLeft: '10px',
                            background: colors.grey[600],
                            color: colors.common.white,
                          }}
                        />
                      )}
                    </div>
                    <Typography style={{ fontSize: '12px' }}>
                      {usuarioItem.email}{' '}
                      {!usuarioItem.emailConfirmed && (
                        <>
                          <IconButton
                            size="small"
                            onClick={handleClickOpenHelp}
                            style={{ marginTop: '-4px' }}
                          >
                            <ErrorOutline
                              fontSize="small"
                              style={{ color: colors.orange[600] }}
                            />
                          </IconButton>
                          <Popover
                            classes={{ paper: classes.helpMessage }}
                            open={openHelp}
                            anchorEl={anchorElHelp}
                            onClose={handleClickCloseHelp}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <Typography className={classes.typographyHelp}>
                              Esse e-mail está pendente de confirmação. Caso não
                              tenha recebido o e-mail, verifique a caixa de spam
                              ou reenvie novamente.
                            </Typography>
                          </Popover>
                        </>
                      )}
                    </Typography>
                  </div>
                  <ButtonMoreOption
                    usuario={usuarioItem}
                    handleAlterar={handleAlterar}
                    handleExcluir={handleExcluir}
                    handleResetSenha={handleResetSenha}
                    handleReenviarEmailConfirmacao={
                      handleReenviarEmailConfirmacao
                    }
                  />
                </div>
              </ListItem>
              <Divider style={{ background: colors.grey[700] }} />
            </React.Fragment>
          ))}
        </List>
        <Zoom in={showAdicionar}>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() => {
              setShowAdicionarDialog(true);
            }}
          >
            <Add />
          </Fab>
        </Zoom>
        <InserirUsuario
          dialogOpen={showAdicionarDialog}
          setDialogOpen={setShowAdicionarDialog}
          handleDialogClose={() => getUsuarios()}
        />
        <AlterarUsuario
          dialogOpen={showAlterarDialog}
          setDialogOpen={setShowAlterarDialog}
          handleDialogClose={() => getUsuarios()}
          usuarioId={usuarioId}
        />
        <DialogConfirm
          contentText="Tem certeza que deseja excluir esse usuário?"
          dialogOpen={dialogConfirmOpen}
          setDialogOpen={setDialogConfirmOpen}
          handleOK={confirmDelete}
        />

        <DialogConfirm
          contentText="O usuário receberá um e-mail com as instruções para cadastrar uma nova senha. Deseja continuar?"
          dialogOpen={dialogConfirmOpenAlterarSenha}
          setDialogOpen={setDialogConfirmOpenAlterarSenha}
          handleOK={confirmAlterarSenha}
        />
      </Dialog>
    </>
  );
};

export default GerenciarUsuario;
