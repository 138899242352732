/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';

const VendaTicketMedio = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [ticketMedio, setTicketMedio] = useState(0);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) => item.identificacao === IdentificacaoGrafico.Vendas_TicketMedio
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const atualizarGrafico = (list, ticketMedioValor, filtro) => {
    const infoGrafico = {
      series: [
        {
          name: 'Vendas',
          data: [],
          type: 'area',
        },
        {
          name: 'Ticket médio',
          data: [],
          type: 'line',
        },
      ],
      options: {
        chart: {
          id: 'ticket-medio',
          toolbar: {
            show: false,
          },
          foreColor: '#fff',
        },
        colors: ['#fff', '#FEB019'],
        fill: {
          type: 'solid',
          opacity: [0.35, 1],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        // markers: {
        //   size: 5,
        //   colors: ['#fff', '#FEB019'],
        //   strokeColor: '#fff',
        //   strokeWidth: 3,
        // },
        xaxis: {
          title: {
            text: filtro === 7 ? 'Meses' : 'Dias',
            offsetX: 0,
            offsetY: 10,
            style: {
              color: undefined,
              fontSize: '14px',
              fontWeight: 600,
            },
          },
          categories: list.map((item) => item.data),
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
          },
        },
        grid: {
          borderColor: '#fff',
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          min: 0,
          title: {
            style: {
              color: '#fff',
            },
          },
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
            formatter: (value) => {
              return `${parseInt(value)}`;
            },
          },
        },
        labels: [],
        tooltip: {
          y: {
            formatter(value) {
              return `${formatarDinheiro(value, true)}`;
            },
          },
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
        },
        legend: {
          position: 'top',
          // horizontalAlign: 'right',
          //   itemMargin: {
          //     horizontal: 5,
          //     vertical: 0
          // },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: [
        { name: 'Vendas', data: list.map((item) => item.valor), type: 'area' },
        {
          name: 'Ticket médio',
          data: list.map(() => ticketMedioValor),
          type: 'line',
        },
      ],
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec('reactchart-example', 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      setInfoGraficoState(undefined);

      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      setTicketMedio(informacaoFiltro.Total);

      const Itens = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          data: item.Descricao,
          valor: item.Total,
        };
      });

      atualizarGrafico(Itens, informacaoFiltro.Total, valor);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTicketMedio(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);
    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
          key: index,
        };
      });
      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  return (
    <>
      <HeaderCard
        titulo="Ticket Médio"
        subtitulo="por mês"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#0B99C1"
        bgNaoSelecionado="#0B597D"
        handle={obterDadosPorFiltro}
      />

      <Totalizador
        valor={ticketMedio}
        titulo="Ticket Médio do Período"
        exibirEmReais
      />

      <div style={{ marginBottom: '5px', marginTop: '20px' }}>
        {ticketMedio === 0 && <GraficoSemDados />}
        {ticketMedio > 0 && (
          <ReactApexChart
            options={infoGraficoState.options}
            series={infoGraficoState.series}
            type="area"
            height="250px"
          />
        )}
      </div>
    </>
  );
};

export default VendaTicketMedio;
