import React from 'react';
import PWAPrompt from 'react-ios-pwa-prompt';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import ChartDataContext from 'store/ChartDataContext';
import LayoutContext from 'store/LayoutContext';

import SnackbarCloseButton from 'components/SnackbarCloseButton';
import HeaderSistema from 'components/Header';
import ServiceWorkerWrapper from 'components/ServiceWorkerWrapper';

import theme from './theme';
import Routes from './routes';
import GlobalStyles from './styles/global';

export default function App() {
  return (
    <LayoutContext>
      <ThemeProvider theme={theme}>
        <HeaderSistema />

        <CssBaseline />
        <SnackbarProvider
          preventDuplicate
          dense
          maxSnack={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          action={(key) => <SnackbarCloseButton key={key} />}
        >
          <ServiceWorkerWrapper />
          <ChartDataContext>
            <Routes />
            <PWAPrompt
              copyTitle="Instalar o aplicativo"
              copyBody="Este site possui funcionalidade de aplicativo. Adicione-o à sua tela inicial para usá-lo em tela cheia e offline."
              copyShareButtonLabel="Pressione o botão 'Compartilhar'"
              copyAddHomeButtonLabel="Adicionar à Tela de Início"
              timesToShow={3}
              copyClosePrompt="Fechar"
              permanentlyHideOnDismiss={false}
            />
            <GlobalStyles />
          </ChartDataContext>
        </SnackbarProvider>
      </ThemeProvider>
    </LayoutContext>
  );
}
