// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Day, DayRange, Calendar } from 'react-modern-calendar-datepicker';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { DateRange } from '@material-ui/icons';

interface DatePickerProps {
  onChange?: (selectedDate: { from: Date; to: Date }) => void;
  bgButtonColor?: string;
  bgDateColor?: string;
  labelColor?: string;
  fontSize?: string;
  fontSizeSmall?: string;
  defaultDateRange: DayRange;
}

const myCustomLocale = {
  // months list by order
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Domingo', // used for accessibility
      short: 'D', // displayed at the top of days' rows
      isWeekend: true, // is it a formal weekend or not?
    },
    {
      name: 'Segunda',
      short: 'S',
    },
    {
      name: 'Terça',
      short: 'T',
    },
    {
      name: 'Quarta',
      short: 'Q',
    },
    {
      name: 'Quinta',
      short: 'Q',
    },
    {
      name: 'Sexta',
      short: 'S',
    },
    {
      name: 'Sábado',
      short: 'S',
      isWeekend: true,
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 0,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // for input range value
  from: 'De',
  to: 'Até',

  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
};

const DatePickerRange = ({
  onChange,
  defaultDateRange,
  bgButtonColor = '#993A50',
  bgDateColor = '#993A50',
  labelColor = '#fff',
  fontSize = '13px',
  fontSizeSmall = '11px',
}: DatePickerProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const useStyles = makeStyles((theme) => ({
    datepicker: {
      width: '100%',
    },
    calendar: {
      zIndex: '99999999',
      // border: '2px solid rgba(18, 17, 19, 0.2)',
    },
    container: {
      marginBottom: '10px',
      marginLeft: '10px',
      marginRight: '10px',
      display: 'flex',
      background: (props: any) => props.bgDateColor,
      borderRadius: '10px',
      border: (props: any) => `2px solid ${props.bgDateColor} !important`,
    },
    divShowDate: {
      width: '100%',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: (props: any) => props.bgDateColor,
      borderRadius: '10px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: (props: any) => props.fontSizeSmall,
      },
      fontSize: (props: any) => props.fontSize,
    },
  }));

  const classes = useStyles({
    bgButtonColor,
    bgDateColor,
    labelColor,
    fontSize,
    fontSizeSmall,
  });

  const obterDataComponenteDatePicker = (date: Day) => {
    return (
      date &&
      `${date.day.toString().padStart(2, '0')}/${date.month
        .toString()
        .padStart(2, '0')}/${date.year}`
    );
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedDayRangeDatePicker, setSelectedDayRangeDatePicker] = useState<
    DayRange
  >(defaultDateRange);

  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>(
    defaultDateRange
  );

  const [minimoMaximoDayRange, setMinimoMaximoDayRange] = useState<DayRange>(
    defaultDateRange
  );

  const onChangeSelectRangeDate = (value: DayRange) => {
    setSelectedDayRangeDatePicker(value);
  };

  const handleOk = () => {
    if (selectedDayRangeDatePicker.from) {
      const { from } = selectedDayRangeDatePicker;
      const to = selectedDayRangeDatePicker.to
        ? selectedDayRangeDatePicker.to
        : selectedDayRangeDatePicker.from;

      setSelectedDayRange({ from, to });

      onChange({
        from: new Date(from.year, from.month - 1, from.day, 0, 0, 0, 0),
        to: new Date(to.year, to.month - 1, to.day, 0, 0, 0, 0),
      });

      setModalIsOpen(false);
    } else {
      enqueueSnackbar('Período informado é inválido.', {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    const dateFrom = new Date(
      defaultDateRange.from?.year,
      defaultDateRange.from?.month,
      defaultDateRange.from?.day,
      0,
      0,
      0,
      0
    );
    dateFrom.setMonth(dateFrom.getMonth() - 3);

    setMinimoMaximoDayRange({
      from: {
        day: dateFrom.getDate(),
        month: dateFrom.getMonth(),
        year: dateFrom.getFullYear(),
      },
      to: defaultDateRange.to,
    });

    setSelectedDayRange(defaultDateRange);
  }, [defaultDateRange]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.divShowDate}>
          <span>
            Período:
            <b>{` ${obterDataComponenteDatePicker(selectedDayRange.from)} `}</b>
            até
            <b>{` ${obterDataComponenteDatePicker(selectedDayRange.to)}`}</b>
          </span>
        </div>
        <div style={{ display: 'flex', marginLeft: '-1px' }}>
          <Button
            variant="contained"
            style={{
              fontSize: '20px',
              padding: 0,
              borderRadius: '9px',
              background: bgButtonColor,
              color: '#fff',
            }}
            onClick={() => {
              setSelectedDayRangeDatePicker(selectedDayRange);
              setModalIsOpen(true);
            }}
          >
            <DateRange fontSize="inherit" />
          </Button>
        </div>
      </div>
      <Dialog open={modalIsOpen} aria-labelledby="responsive-dialog-title">
        <DialogContent style={{ padding: 0, margin: 0, background: 'white' }}>
          <Calendar
            value={selectedDayRangeDatePicker}
            onChange={onChangeSelectRangeDate}
            shouldHighlightWeekends
            locale={myCustomLocale}
            wrapperClassName={classes.datepicker}
            calendarClassName={classes.calendar}
            maximumDate={minimoMaximoDayRange.to}
            minimumDate={minimoMaximoDayRange.from}
            renderFooter={() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1rem 2rem',
                  height: '68.5px',
                }}
              >
                <Button
                  color="primary"
                  onClick={() => setModalIsOpen(false)}
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button color="primary" onClick={handleOk} variant="contained">
                  OK
                </Button>
              </div>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DatePickerRange;
