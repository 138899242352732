/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import DatePickerRange from 'components/DatePickerRange';
import dateToDayDatePicker from 'helpers/dateFormat';
import { DayRange } from 'react-modern-calendar-datepicker';
import { maxHourDate, minHourDate } from 'helpers/dateMinMax';

const VendaPorHora = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [totalVendas, setTotalVendas] = useState(0);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) => item.identificacao === IdentificacaoGrafico.Vendas_VendasPorHora
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const atualizarGrafico = (list) => {
    const listSort = list.sort(
      (a, b) => parseFloat(a.hora) - parseFloat(b.hora)
    );

    const infoGrafico = {
      series: [
        {
          name: 'Vendas',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'area',
          id: 'venda-por-hora',
          toolbar: {
            show: false,
          },
          foreColor: '#fff',
        },
        colors: ['#fff'],
        fill: {
          gradient: {
            gradientToColors: ['#fff'],
            opacityFrom: 1,
            opacityTo: 0.5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        markers: {
          size: 5,
          colors: ['#fff'],
          strokeColor: '#fff',
          strokeWidth: 3,
        },
        xaxis: {
          categories: listSort.map((item) => `${item.hora}h`),
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
          },
        },
        grid: {
          borderColor: '#fff',
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          min: 0,
          title: {
            style: {
              color: '#fff',
            },
          },
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
            formatter: (value) => {
              return `${parseInt(value)}`;
            },
          },
        },
        labels: [],
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: [{ name: 'Vendas', data: listSort.map((item) => item.valor) }],
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec('reactchart-example', 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      setInfoGraficoState(undefined);

      const informacao = obterDadosGrafico(chartData);

      const informacoesFiltro = informacao.dados.filter((item) => {
        const dataItem = new Date(item.Data);
        return (
          dtInicial &&
          dtFinal &&
          dataItem >= minHourDate(dtInicial) &&
          dataItem <= maxHourDate(dtFinal)
        );
      });

      let valTotal = 0;
      const listItens = Array<any>();

      for (let index = 0; index < informacoesFiltro.length; index += 1) {
        const infoFiltro = informacoesFiltro[index];
        valTotal += infoFiltro.Total;
        for (
          let indexDado = 0;
          indexDado < infoFiltro.Dados.length;
          indexDado += 1
        ) {
          const dado = infoFiltro.Dados[indexDado];

          const objIndex = listItens.findIndex(
            (obj) => obj.hora === parseInt(dado.Descricao)
          );

          if (objIndex === -1) {
            listItens.push({
              id: listItens.length - 1,
              hora: parseInt(dado.Descricao),
              valor: parseInt(dado.Total),
            });
          } else {
            const itemUpdate = listItens[objIndex];

            listItens[objIndex] = {
              id: itemUpdate.id,
              hora: itemUpdate.hora,
              valor: itemUpdate.valor + dado.Total,
            };
          }
        }
      }

      setTotalVendas(valTotal);
      atualizarGrafico(listItens);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const resetGrafico = () => {
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);

      setDataUltimaSincronizacao(dateTo.toLocaleString());

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item) => {
        return {
          texto: item.Data,
          valor: item.Data,
          possuiDados: item.Total > 0,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
  };

  return (
    <>
      <HeaderCard
        titulo="Por Hora"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />

      <DatePickerRange
        bgButtonColor="#FE6383"
        bgDateColor="#993A50"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Quantidade de Vendas"
        exibirEmReais={false}
      />

      <div style={{ marginBottom: '5px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <ReactApexChart
            options={infoGraficoState.options}
            series={infoGraficoState.series}
            type="area"
            height="250px"
          />
        )}
      </div>
    </>
  );
};

export default VendaPorHora;
