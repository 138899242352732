import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  colors,
  FormHelperText,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(() => ({
  inputLabelProps: {
    color: colors.grey[400],
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    '& :not(.Mui-error)': {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },

      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.grey[300],
          color: colors.grey[300],
        },
        '&:hover fieldset :not(.Mui-disabled)': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& input:disabled': {
          color: colors.grey[200],
          background: '#2e3b42',
        },
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: colors.grey[300],
    },
  },
}))(FormControl);

const FormInputPassword = (props: any) => {
  const classes = useStyles();

  const {
    name,
    label,
    control,
    error,
    messageError,
    autoComplete,
    labelWidth,
    required,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const Input = (
    <CssTextField required={required} margin="normal" fullWidth>
      <InputLabel className={classes.inputLabelProps}>{label}</InputLabel>
      <Controller
        as={
          <OutlinedInput
            required
            error={error}
            type={showPassword ? 'text' : 'password'}
            autoComplete={autoComplete}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  color="secondary"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={labelWidth}
          />
        }
        name={name}
        control={control}
      />

      {error && <FormHelperText>{messageError}</FormHelperText>}
    </CssTextField>
  );

  return (
    <Controller
      as={Input}
      name={name}
      control={control}
      label={label}
      fullWidth
      margin="normal"
      variant="outlined"
      error={error}
    />
  );
};

export default FormInputPassword;
