export type TipoSistema = 1 | 2;

export type ListaItensSistema = {
  icone: string;
  name: string;
  value: number;
  manifest: string;
  nomeExibicao: string;
};

const TipoSistemaEnum = {
  ZENDAR: 0,
  POWERSTOCK: 1,
  STI3: 2,

  properties: {
    1: {
      name: 'zendar',
      value: 0,
      nomeExibicao: 'Zendar Dashboard',
      manifest: `${process.env.PUBLIC_URL}/manifestZendar.json`,
    },
    2: {
      name: 'powerstock',
      value: 1,
      nomeExibicao: 'Powerstock Dashboard',
      manifest: `${process.env.PUBLIC_URL}/manifestPowerstock.json`,
    },
    3: {
      name: 'sti3',
      value: 2,
      nomeExibicao: 'Sti3 Dashboard',
      manifest: `${process.env.PUBLIC_URL}/manifest.json`,
    },
  },
};

export default TipoSistemaEnum;
