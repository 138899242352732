import React from 'react';
import { Card, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VendaPorLojaV1 from 'components/Grafico/TodasLojas/VendaPorLoja/v1/VendaPorLoja';
import VendaPorLojaV2 from 'components/Grafico/TodasLojas/VendaPorLoja/v2/VendaPorLoja';
import VendaTodasLojasPorFormaPagamentoV1 from 'components/Grafico/TodasLojas/VendaTodasLojasPorFormaPagamento/v1/VendaTodasLojasPorFormaPagamento';
import VendaTodasLojasPorFormaPagamentoV2 from 'components/Grafico/TodasLojas/VendaTodasLojasPorFormaPagamento/v2/VendaTodasLojasPorFormaPagamento';
import VendaPorOrigemV1 from 'components/Grafico/TodasLojas/VendaPorOrigem/v1/VendaPorOrigem';
import VendaPorOrigemV2 from 'components/Grafico/TodasLojas/VendaPorOrigem/v2/VendaPorOrigem';

import { useLayout } from 'store/LayoutContext';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '100%',
    minheight: '100%',
  },
}));

const Vendas: React.FC = () => {
  const classes = useStyles();
  const { setTitlePage } = useLayout();
  const { obterVersaoGrafico } = useChartData();

  React.useEffect(() => {
    setTitlePage('Vendas');
  });

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={6} xl={12} xs={12}>
          <Card style={{ background: '#0B99C1' }}>
            {obterVersaoGrafico(
              IdentificacaoGrafico.TodasEmpresas_VendasPorLoja
            ) === 1 ? (
              <VendaPorLojaV1 />
            ) : (
              <VendaPorLojaV2 />
            )}
          </Card>
        </Grid>
        <Grid item xl={6} xs={12}>
          <Card style={{ background: '#4B5360' }}>
            {obterVersaoGrafico(
              IdentificacaoGrafico.TodasEmpresas_VendasPorFormaPagto
            ) === 1 ? (
              <VendaTodasLojasPorFormaPagamentoV1 />
            ) : (
              <VendaTodasLojasPorFormaPagamentoV2 />
            )}
          </Card>
        </Grid>
        <Grid item xl={6} xs={12}>
          <Card style={{ background: '#7C66C8' }}>
            {obterVersaoGrafico(
              IdentificacaoGrafico.TodasEmpresas_VendasPorOrigem
            ) === 1 ? (
              <VendaPorOrigemV1 />
            ) : (
              <VendaPorOrigemV2 />
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Vendas;
