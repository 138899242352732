const ConstanteMensagemValidacao = {
  CAMPO_OBRIGATORIO: 'O campo é obrigatório',

  EMAIL_INVALIDO: 'O e-mail informado é inválido',

  SENHA_MINIMO_SEIS_CARACTERES: 'A senha deve possuir pelo menos 6 caracteres',

  SENHA_NAO_COINCIDEM: 'As senhas não coincidem',
};

export default ConstanteMensagemValidacao;
