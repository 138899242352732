import React, { createContext, useCallback, useContext } from 'react';

import { useSnackbar } from 'notistack';

import auth from 'auth';
import usePersistedState from 'helpers/usePersistedState';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import usePersistedStateIndexedDB from 'helpers/usePersistedStateIndexedDB';

export interface DadosInterface {
  lojaId: string;
  identificacao: number;
  dataUltimaSincronizacao: Date;
  dados: any;
  versao: number;
}

export interface LojaInterface {
  LojaId: string;
  NomeFantasia: string;
  CidadeUf: string;
  DataHoraUltimaSincronizacao: string;
}

interface ChartDataContextProps {
  chartData: Array<DadosInterface>;
  setChartData: React.Dispatch<React.SetStateAction<DadosInterface[]>>;
  syncData: (lojaId?: string) => Promise<boolean>;
  loja: LojaInterface;
  setLoja: React.Dispatch<React.SetStateAction<LojaInterface>>;
  consultarAposLogin: boolean;
  setConsultarAposLogin: React.Dispatch<React.SetStateAction<boolean>>;
  obterVersaoGrafico: (identificacaGrafico: number) => number;
}

const ChartDataContext = createContext<ChartDataContextProps>(
  {} as ChartDataContextProps
);

interface CharDataContextProviderProps {
  children: React.ReactNode;
}

export default function LayoutProvider({
  children,
}: CharDataContextProviderProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [chartData, setChartData] = usePersistedStateIndexedDB<
    Array<DadosInterface>
  >('chartDataPersistedState', [{} as DadosInterface]);

  const [loja, setLoja] = usePersistedState<LojaInterface>(
    'lojaPersistedState',
    {} as LojaInterface
  );

  const [consultarAposLogin, setConsultarAposLogin] = usePersistedState<
    boolean
  >('consultaAposLoginPersistedState', false);

  interface DadosGraficos {
    lojaId: string;
    identificacao: number;
    dataUltimaSincronizacao: Date;
    dados: any;
    versao: number;
  }

  const syncData = async (lojaId): Promise<boolean> => {
    let lojaIdConsulta = lojaId;

    if (!lojaIdConsulta) {
      const lojaExistente =
        auth.getLojas().filter((lojaItem) => lojaItem.LojaId === loja.LojaId)
          .length > 0;

      if (lojaExistente) lojaIdConsulta = loja.LojaId;
    }

    if (!lojaIdConsulta) {
      const primeiraLoja = auth.getLojas()[0];
      lojaIdConsulta = primeiraLoja.LojaId;
      setLoja(primeiraLoja);
    }

    const response = await api.get<void, ResponseApi<Array<DadosGraficos>>>(
      ConstanteEnderecoWebservice.GRAFICO_LISTAR,
      {
        params: {
          lojaId: lojaIdConsulta,
        },
      }
    );

    if (response?.sucesso) {
      const retornoFormatado = response.dados.map((obj) => ({
        ...obj,
        dados: JSON.parse(obj.dados).Filtros || JSON.parse(obj.dados).Dias,
      }));

      if (!consultarAposLogin) {
        setConsultarAposLogin(true);
      }

      if (retornoFormatado.length === 0) {
        enqueueSnackbar(
          'A loja selecionada não possui informações sincronizadas.',
          { variant: 'warning' }
        );

        return false;
      }

      setChartData(retornoFormatado);
      return true;
    }

    if (response?.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }

    return false;
  };

  const obterVersaoGrafico = useCallback(
    (identificacaoGrafico: number): number => {
      let versao = 1;

      if (chartData.length > 0) {
        const informacoesGraficoPorIdentificacao = chartData.filter(
          (item) => item.identificacao === identificacaoGrafico
        );

        if (informacoesGraficoPorIdentificacao.length > 0) {
          versao = informacoesGraficoPorIdentificacao[0].versao;
        }
      }

      return versao;
    },
    [chartData]
  );

  return (
    <ChartDataContext.Provider
      value={{
        chartData,
        setChartData,
        syncData,
        loja,
        setLoja,
        consultarAposLogin,
        setConsultarAposLogin,
        obterVersaoGrafico,
      }}
    >
      {children}
    </ChartDataContext.Provider>
  );
}

export function useChartData(): ChartDataContextProps {
  const context = useContext(ChartDataContext);

  if (!context)
    throw new Error('useChartData must be used within a ChartDataProvider.');

  return context;
}
