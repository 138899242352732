import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

interface ButtonExcluirProps {
  setDialogOpen(isOpen: boolean): void;
  onConfirm(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperMenu: {
      background: colors.grey[900],
      boxShadow: 'none',
      minWidth: '150px',
    },
    iconAction: {
      marginRight: theme.spacing(1),
    },
  })
);

function ButtonOptionsLoja({ onConfirm, setDialogOpen }: ButtonExcluirProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        ria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="secondary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon className={classes.iconAction} fontSize="small" />
      </IconButton>
      <Menu
        id="long-menu"
        classes={{
          paper: classes.paperMenu,
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorPosition={{
          left: 0,
          top: 0,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
            setAnchorEl(null);
            onConfirm();
          }}
        >
          <DeleteIcon className={classes.iconAction} fontSize="small" />
          Remover
        </MenuItem>
      </Menu>
    </>
  );
}

export default ButtonOptionsLoja;
