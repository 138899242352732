import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import ProgressBar from 'components/ProgressBar';
import Totalizador from 'components/Totalizador';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import DatePickerRange from 'components/DatePickerRange';
import dateToDayDatePicker from 'helpers/dateFormat';
import { DayRange } from 'react-modern-calendar-datepicker';
import extractInformationChartV2 from 'helpers/extractInformationChartV2';

const VendaPorVendedor = () => {
  const { chartData, loja } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [progressBarList, setProgressBarList] = useState([] as any);
  const [valorTotal, setValorTotal] = useState(0);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });

  const resetGrafico = () => {
    setProgressBarList([]);
    setValorTotal(0);
  };

  const obterDadosGrafico = useCallback(
    (dados) => {
      const informacoesFiltro = dados.filter(
        (item) =>
          item.identificacao ===
            IdentificacaoGrafico.Vendas_VendasPorVendedor &&
          item.lojaId === loja.LojaId
      );

      if (informacoesFiltro.length > 0) {
        return {
          ...informacoesFiltro[0],
        };
      }

      return null;
    },
    [loja.LojaId]
  );

  const calcularPorcentagens = (list, total) => {
    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    setProgressBarList(
      listPorcentagem.sort(
        (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
      )
    );
  };

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      const informacao = obterDadosGrafico(chartData);

      if (!informacao) {
        setProgressBarList(undefined);
        return;
      }

      const { valTotal, listItens } = extractInformationChartV2(
        informacao,
        dtInicial,
        dtFinal
      );

      setValorTotal(valTotal);
      calcularPorcentagens(listItens, valTotal);
    },
    [chartData, obterDadosGrafico]
  );

  const handleOrdem = (ordem: string) => {
    const list =
      ordem === 'desc'
        ? progressBarList.sort(
            (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
          )
        : progressBarList.sort(
            (a, b) => parseFloat(a.porcentagem) - parseFloat(b.porcentagem)
          );

    setProgressBarList([...list]);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);

      setDataUltimaSincronizacao(dateTo.toLocaleString());

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item) => {
        return {
          texto: item.Data,
          valor: item.Data,
          possuiDados: item.Total > 0,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
  };

  return (
    <div style={{ paddingBottom: '5px' }}>
      <HeaderCard
        titulo="Por Vendedores"
        atualizadoEm={dataUltimaSincronizacao}
        handleOrdem={handleOrdem}
        exibirFiltro={false} // TODO rever se é necessário
      />

      <DatePickerRange
        bgButtonColor="#848884"
        bgDateColor="#414241"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador valor={valorTotal} titulo="Total das Vendas" />

      {progressBarList?.length === 0 && <GraficoSemDados />}

      {progressBarList &&
        progressBarList.map((item) => (
          <ProgressBar
            key={`${item.id}`}
            bgcolor="#1E2326"
            valor={item.valor}
            nome={item.nome}
            completed={item.porcentagem}
          />
        ))}
    </div>
  );
};

export default VendaPorVendedor;
