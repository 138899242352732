import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import formatarDinheiro from 'helpers/moneyFormat';

const useStyles = makeStyles(() => ({
  container: {
    height: '55px',
    background: '#fff',
    marginBottom: '10px',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    width: '49.80%',
  },
  rowValor: {
    color: '#1E2326',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
  },
  rowTitulo: {
    color: '#1E2326',
    fontSize: '16px',
    marginTop: '-15px',
    textAlign: 'center',
  },
}));

const TotalizadorDividido = ({
  valorEsquerdo,
  tituloEsquerdo,
  exibirEmReaisEsquerdo = true,
  valorDireito,
  tituloDireito,
  exibirEmReaisDireito = true,
}) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={classes.container}>
          <div className={classes.rowValor}>
            {exibirEmReaisEsquerdo && (
              <span style={{ fontSize: '12px' }}>R$ </span>
            )}
            <span>
              {exibirEmReaisEsquerdo
                ? formatarDinheiro(valorEsquerdo, false)
                : valorEsquerdo}
            </span>
          </div>
          <span className={classes.rowTitulo}>{tituloEsquerdo}</span>
        </div>
        <div className={classes.container}>
          <div className={classes.rowValor}>
            {exibirEmReaisDireito && (
              <span style={{ fontSize: '12px' }}>R$ </span>
            )}
            <span>
              {exibirEmReaisDireito
                ? formatarDinheiro(valorDireito, false)
                : valorDireito}
            </span>
          </div>
          <span className={classes.rowTitulo}>{tituloDireito}</span>
        </div>
      </div>
    </>
  );
};

export default TotalizadorDividido;
