import React, { useState, useEffect, useCallback, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { useSnackbar } from 'notistack';
import JSZip from 'jszip';
import JSFiledownload from 'js-file-download';
import ReactApexChart from 'react-apexcharts';
import { DayRange } from 'react-modern-calendar-datepicker';

import { useChartData } from 'store/ChartDataContext';
import formatarDinheiro from 'helpers/moneyFormat';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import dateToDayDatePicker from 'helpers/dateFormat';
import { maxHourDate, minHourDate } from 'helpers/dateMinMax';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'auth';

import GraficoSemDados from 'components/GraficoSemDados';
import HeaderCard from 'components/HeaderCard';
import LegendaGrafico from 'components/LegendaGrafico';
import Totalizador from 'components/Totalizador';
import DatePickerRange from 'components/DatePickerRange';
import DialogConfirm from 'components/DialogConfirm';
import Loading from 'components/Loading';

type ResponseDownload = {
  nomeArquivo: string;
  arquivo: string;
};

const VendaTodasLojasPorFormaPagamento = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao ===
        IdentificacaoGrafico.TodasEmpresas_VendasPorFormaPagto
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const atualizarGrafico = (list, total) => {
    const colors = [
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#03A9F4',
      '#4CAF50',
      '#F9CE1D',
      '#FF9800',
      '#33B2DF',
      '#c9509b',
    ];

    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    const listSort = listPorcentagem.sort(
      (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
    );

    setLegenda(
      listSort.map((item, index) => ({
        cor: colors[index <= colors.length - 1 ? index : index % colors.length],
        formaPagto: item.nome,
        valor: item.valor,
        porcentagem: `${item.porcentagem.toFixed(1)}%`,
      }))
    );

    const infoGrafico = {
      series: listSort.map((item) => item.porcentagem),

      options: {
        stroke: {
          lineCap: 'round',
        },
        chart: {
          id: 'vendas-por-forma-pagamento',
        },
        colors,
        labels: listSort.map((item) => item.nome),
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          labels: {
            colors: '#fff',
            useSeriesColors: false,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => `${val.toFixed(1)}%`,
          },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: listSort.map((item) => item.porcentagem),
      options: {
        ...infoGrafico.options,
        labels: listSort.map((item) => item.nome),
      },
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec(newInfoGrafico.options.chart.id, 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      const informacao = obterDadosGrafico(chartData);

      const informacoesFiltro = informacao.dados.filter((item) => {
        const dataItem = new Date(item.Data);

        return (
          dtInicial &&
          dtFinal &&
          dataItem >= minHourDate(dtInicial) &&
          dataItem <= maxHourDate(dtFinal)
        );
      });

      setInfoGraficoState(undefined);

      let valTotal = 0;
      const listItens = Array<any>();

      for (let index = 0; index < informacoesFiltro.length; index += 1) {
        const infoFiltro = informacoesFiltro[index];
        valTotal += infoFiltro.ValorTotal;
        for (
          let indexDado = 0;
          indexDado < infoFiltro.Dados.length;
          indexDado += 1
        ) {
          const dado = infoFiltro.Dados[indexDado];

          const objIndex = listItens.findIndex(
            (obj) => obj.nome === dado.Descricao
          );

          if (objIndex === -1) {
            listItens.push({
              id: listItens.length - 1,
              nome: dado.Descricao,
              valor: dado.ValorTotal,
            });
          } else {
            const itemUpdate = listItens[objIndex];

            listItens[objIndex] = {
              id: itemUpdate.id,
              nome: itemUpdate.nome,
              valor: itemUpdate.valor + dado.ValorTotal,
            };
          }
        }
      }

      setTotalVendas(valTotal);
      atualizarGrafico(listItens, valTotal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const resetGrafico = () => {
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);

      setDataUltimaSincronizacao(dateTo.toLocaleString());
      setDataInicio(dateTo);
      setDataFim(dateTo);

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item) => {
        return {
          texto: item.Data,
          valor: item.Data,
          possuiDados: item.ValorTotal > 0,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      setTotalVendas(0);
    }
  }, [chartData, obterDadosGrafico, obterDadosPorFiltro]);

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
    setDataInicio(selectedDate.from);
    setDataFim(selectedDate.to);
  };

  async function handleDownloadReport() {
    setLoading(true);
    setDialogOpen(false);
    const zip = new JSZip();
    const empresaId = auth.empresaId();

    const response = await api.get<void, ResponseApi<ResponseDownload[]>>(
      ConstanteEnderecoWebservice.EXPORTAR_FORMA_PAGAMENTO_LOJA,
      {
        params: {
          empresaId,
          dataInicio,
          dataFim,
        },
      }
    );
    if (response.avisos) {
      response.avisos.forEach((avisos) => {
        enqueueSnackbar(avisos, { variant: 'warning' });
      });
    }
    if (response.dados && response.sucesso) {
      response.dados.forEach((item) => {
        zip.file(
          item.nomeArquivo,
          Uint8Array.from(atob(item.arquivo), (code) => code.charCodeAt(0))
        );
      });
      await zip.generateAsync({ type: 'blob' }).then((content) => {
        JSFiledownload(content, 'Lojas.zip');
      });
    }
    setLoading(false);
  }

  return (
    <>
      {loading && <Loading />}
      <HeaderCard
        titulo="Por Formas de Pagamento"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
        appearButtonDownload
        handleDownloadReport={setDialogOpen}
        downloadReportDisabled={totalVendas === 0}
      />

      <DatePickerRange
        bgButtonColor="#4B5360"
        bgDateColor="#323B40"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total das Vendas"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            <ReactApexChart
              options={infoGraficoState.options}
              series={infoGraficoState.series}
              type="pie"
              height="300px"
            />
            {legenda.map((item) => (
              <LegendaGrafico
                key={item.formaPagto}
                cor={item.cor}
                descricao={item.formaPagto}
                valor={formatarDinheiro(item.valor, true)}
                porcentagem={item.porcentagem}
                corFundo="#4B5360"
                corPrincipal="#323B40"
              />
            ))}
          </div>
        )}
      </div>
      <DialogConfirm
        dialogOpen={dialogOpen}
        title="Exportar formas de pagamento por loja"
        contentText="Deseja exportar as formas de pagamento por loja do período selecionado?"
        handleOK={handleDownloadReport}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
};

export default VendaTodasLojasPorFormaPagamento;
