import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { colors, makeStyles, withStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  inputLabelProps: {
    color: colors.grey[400],
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    '& :not(.Mui-error)': {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },

      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.grey[300],
          color: colors.grey[300],
        },
        '&:hover fieldset :not(.Mui-disabled)': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& input:disabled': {
          color: colors.grey[200],
          background: '#2e3b42',
        },
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: colors.grey[300],
    },
  },
}))(TextField);

const FormInput = (props: any) => {
  const classes = useStyles();
  const { setValue, ...rest } = props;
  const { name, label, control } = props;

  const Input = (
    <CssTextField
      InputLabelProps={{ className: classes.inputLabelProps }}
      inputProps={{
        onBlur: (e: any) => {
          if (setValue) {
            setValue(name, e.target.value.trim());
          }
        },
      }}
    />
  );

  return (
    <Controller
      as={Input}
      name={name}
      control={control}
      defaultValue=""
      label={label}
      {...rest}
    />
  );
};

export default FormInput;
