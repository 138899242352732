import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  GuardProvider,
  GuardedRoute as Guard,
  GuardFunction,
} from 'react-router-guards';
import Loading from 'components/Loading';
import { useLayout } from 'store/LayoutContext';

import Login from 'pages/Autenticacao/Login';
import RecuperarSenha from 'pages/Autenticacao/RecuperarSenha';
import RedefinirSenha from 'pages/Autenticacao/RedefinirSenha';
import ConfirmarEmail from 'pages/Autenticacao/ConfirmarEmail';

import Menu from 'components/Menu';
import VendasPorLoja from 'pages/Dashboard/VendasPorLoja';
import VendasTodasLojas from 'pages/Dashboard/VendasTodasLojas';
import Produtos from 'pages/Dashboard/Produtos';
import ContasPagar from 'pages/Dashboard/ContasPagar';
import ContasReceber from 'pages/Dashboard/ContasReceber';

import {
  GuardFunctionRouteProps,
  GuardToRoute,
  Next,
} from 'react-router-guards/dist/types';
import auth from 'auth';
import ConstanteRotas from 'constants/rotas';

const requireLogin: GuardFunction = (
  to: GuardToRoute,
  from: GuardFunctionRouteProps | null,
  next: Next
) => {
  if (process.env.NODE_ENV === 'production' && to.meta.appInsights) {
    to.meta.appInsights.trackPageView({
      isLoggedIn: auth.autenticado(),
      name: auth.autenticado()
        ? auth.getUserIdentityAppInsights()
        : 'Não autenticado',
      uri: to.location,
    });
  }

  if (to.meta.auth) {
    if (auth.autenticado()) {
      next();
    }
    next.redirect('/');
    return;
  }

  next();
};

const Routes: React.FC = () => {
  const { loading, appInsights } = useLayout();

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <GuardProvider guards={[requireLogin]}>
        <Switch>
          <Guard
            component={Login}
            exact
            path={ConstanteRotas.LOGIN}
            meta={{ auth: false, appInsights }}
          />
          <Guard
            component={RecuperarSenha}
            path={ConstanteRotas.RECUPERAR_SENHA}
            meta={{ auth: false, appInsights }}
          />
          <Guard
            component={RedefinirSenha}
            path={ConstanteRotas.REDEFINIR_SENHA}
            meta={{ auth: false, appInsights }}
          />
          <Guard
            component={ConfirmarEmail}
            path={ConstanteRotas.CONFIRMAR_EMAIL}
            meta={{ auth: false, appInsights }}
          />
          <Route exact path={[...Object.values(ConstanteRotas), '']}>
            <Menu>
              <Guard
                component={VendasPorLoja}
                path={ConstanteRotas.VENDAS_POR_LOJA}
                meta={{ auth: true, appInsights }}
              />
              <Guard
                component={VendasTodasLojas}
                path={ConstanteRotas.VENDAS_TODAS_LOJAS}
                meta={{ auth: true, appInsights }}
              />
              <Guard
                component={Produtos}
                path={ConstanteRotas.PRODUTOS}
                meta={{ auth: true, appInsights }}
              />
              <Guard
                component={ContasPagar}
                path={ConstanteRotas.CONTAS_PAGAR}
                meta={{ auth: true, appInsights }}
              />
              <Guard
                component={ContasReceber}
                path={ConstanteRotas.CONTAS_RECEBER}
                meta={{ auth: true, appInsights }}
              />
            </Menu>
          </Route>

          <Guard path="*" component={Login} />
        </Switch>
      </GuardProvider>
    </BrowserRouter>
  );
};

export default Routes;
