import {
  makeStyles,
  Theme,
  createStyles,
  colors,
  fade,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      background: colors.grey[800],
      height: '100%',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: theme.spacing(6),
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    helpMessage: {
      background: colors.grey[700],
    },
    typographyHelp: {
      padding: theme.spacing(2),
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    text: {
      fontSize: '18px',
    },
    listItemAction: {
      marginRight: theme.spacing(2),
    },
  })
);

export default useStyles;
