import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  toggleButtonGroup: {
    width: '100%',
    height: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    background: (props: any) => props.bgNaoSelecionado,
    borderRadius: '10px !important',
  },
  toggleButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: (props: any) => props.fontSizeSmall,
    },
    fontSize: (props: any) => props.fontSize,

    width: '100%',
    color: (props: any) => `${props.color} !important`,
    border: (props: any) => `2px solid ${props.bgNaoSelecionado} !important`,
    borderRadius: '10px !important',
    textTransform: 'none',
  },
}));

const RadioButtonFiltros = ({
  listItems,
  bgSelecionado,
  bgNaoSelecionado,
  color = '#fff',
  fontSize = '13px',
  fontSizeSmall = '11px',
  handle,
  opcaoPadrao = -1,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [option, setOption] = useState(-1);

  const handleChange = (event, newOption) => {
    if (newOption !== null) {
      const itemButton = listItems.filter(
        (item) => item.valor === newOption
      )[0];

      if (!itemButton.possuiDados) {
        enqueueSnackbar('Não existem informações para serem exibidas.', {
          variant: 'info',
        });
        return;
      }
      setOption(newOption);
      handle(newOption);
    }
  };

  const classes = useStyles({
    bgNaoSelecionado,
    color,
    fontSize,
    fontSizeSmall,
  });

  useEffect(() => {
    const itemsComValor = listItems.filter((item) => item.possuiDados);

    if (itemsComValor.length > 0)
      if (opcaoPadrao !== -1) {
        const filtroPadrao = itemsComValor.filter(
          (filtroItem) => filtroItem.valor === opcaoPadrao
        );

        if (filtroPadrao[0]) {
          setOption(opcaoPadrao);
        } else {
          setOption(itemsComValor[itemsComValor.length - 1].valor);
        }
      } else {
        setOption(itemsComValor[itemsComValor.length - 1].valor);
      }
  }, [listItems, opcaoPadrao]);

  return (
    <>
      {listItems && listItems[0]?.texto?.length > 0 && option >= 0 ? (
        <div className={classes.container}>
          <ToggleButtonGroup
            size="small"
            value={option}
            exclusive
            onChange={handleChange}
            className={classes.toggleButtonGroup}
          >
            {listItems.map((item) => (
              <ToggleButton
                key={`${item.valor}`}
                className={classes.toggleButton}
                style={{
                  fontWeight: option === item.valor ? 'bold' : 'normal',
                  background:
                    option === item.valor ? bgSelecionado : bgNaoSelecionado,
                }}
                value={item.valor}
              >
                <span>{item.texto}</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RadioButtonFiltros;
