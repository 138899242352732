const ConstanteEnderecoWebservice = {
  AUTENTICACAO_LOGIN: 'api/autenticacao/login',
  AUTENTICACAO_LOGOFF: 'api/autenticacao/logoff',
  AUTENTICACAO_RECUPERAR_SENHA: 'api/autenticacao/recuperar-senha/',
  AUTENTICACAO_ALTERAR_SENHA: 'api/autenticacao/alterar-senha',
  AUTENTICACAO_REFRESH_TOKEN: 'api/autenticacao/refresh-token',

  AUTENTICACAO_INTEGRACAO_LOGIN: 'api/AutenticacaoIntegracao/login',
  AUTENTICACAO_INTEGRACAO_LOGOFF: 'api/AutenticacaoIntegracao/logoff',
  AUTENTICACAO_INTEGRACAO_REFRESH_TOKEN:
    'api/AutenticacaoIntegracao/refresh-token',

  USUARIO_LISTAR: 'api/usuario/listar',
  USUARIO_EXCLUIR: 'api/usuario/excluir',
  USUARIO_INSERIR: 'api/usuario/inserir',
  USUARIO_ALTERAR: 'api/usuario/alterar',
  USUARIO_OBTER: 'api/usuario/obter',
  USUARIO_CONFIRMAR_EMAIL: 'api/usuario/confirmar-email',
  USUARIO_REENVIAR_CONFIRMACAO_EMAIL: 'api/usuario/enviar-email-confirmacao/',

  EXPORTAR_FORMA_PAGAMENTO_LOJA: 'api/grafico/exportar-formapagto-loja',

  LOJA_LISTAR: 'api/loja/listar',
  LOJA_DESATIVAR: 'api/Modulo/desativar',

  RELATORIOS_DISPONIVEIS: 'api/Relatorio/obter-disponiveis',
  OBTER_RELATORIO: 'api/Relatorio/obter-relatorio',

  GRAFICO_LISTAR: 'api/grafico/listar',
};

export default ConstanteEnderecoWebservice;
