import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: 'black',
    zIndex: 9998,
    opacity: '0.8',
  },
  containerLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
}));

const Loading = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.containerLoading}>
        <ScaleLoader
          height="40px"
          radius="0px"
          color={theme.palette.primary.main}
        />
      </div>
    </div>
  );
};

export default Loading;
