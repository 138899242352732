import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loading from 'components/ProgressBar';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      const evt = new CustomEvent('pwaUpdated');
      window.dispatchEvent(evt);
    }
  },
});
// reportWebVitals();
