import { createMuiTheme, colors } from '@material-ui/core';
import shadowsCustom from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: colors.teal[400],
    },
    secondary: {
      main: colors.common.white,
    },
    text: {
      primary: colors.common.white,
      secondary: colors.teal[400],
    },
  },
  shadows: shadowsCustom as any,
  typography,
});

export default theme;
