import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    background: '#0B597D',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: '#0B99C1',
  },
  porcentagem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
  },
  descricao: {
    color: (props: any) => props.cor,
    fontSize: '14px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  obs: {
    color: (props: any) => props.cor,
    fontSize: '12px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  valorTotal: {
    fontSize: '12px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  quantidade: {
    fontSize: '12px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
  },
}));

const ProdutoEmEstoque = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.Produtos_ProdutosEmEstoque
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      const items = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          descricao: item.Descricao,
          obs: item.InformacaoAdicional.Descricao,
          quantidade: item.InformacaoAdicional[0].Total,
          valorTotal: item.Total,
        };
      });

      setTotalVendas(informacaoFiltro.Total);
      setLegenda(items);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Dados.length > 0,
          key: index,
        };
      });

      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  const InfoGrafico = (props) => {
    const { descricao, valorTotal, quantidade } = props;
    const classes = useStyles({ cor: 'white' });

    return (
      <>
        <div className={classes.container}>
          <div className={classes.descricao}>
            <div>{descricao}</div>
            <div className={classes.valorTotal}>
              <p>{formatarDinheiro(valorTotal, true)}</p>
            </div>
          </div>
          <div
            style={{
              paddingBottom: '5px 0 5px 0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className={classes.obs} />
            <div className={classes.quantidade}>{quantidade}</div>
          </div>
        </div>
        <Divider className={classes.divider} />
      </>
    );
  };
  return (
    <>
      <HeaderCard
        titulo="Produtos em Estoque"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#0B99C1"
        bgNaoSelecionado="#0B597D"
        handle={obterDadosPorFiltro}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total do Estoque"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas && (
          <div style={{ paddingBottom: '5px' }}>
            {legenda.map((item) => (
              <InfoGrafico
                key={item.id}
                descricao={item.descricao}
                valorTotal={item.valorTotal}
                obs={item.obs}
                quantidade={item.quantidade}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProdutoEmEstoque;
