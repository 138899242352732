import React, { useCallback, useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
  AppBar,
  Button,
  Checkbox,
  colors,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Popover,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBack, HelpOutline } from '@material-ui/icons';
import Transition from 'components/TransitionDefault';
import auth from 'auth';
import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import ButtonLoading from 'components/Button/ButtonLoading';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormInputAutoComplete from 'components/FormInputAutoComplete';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import Loading from 'components/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      height: '100%',
      '& .MuiDialogContent-root': {
        padding: '0px',
        background: colors.grey[800],
      },
    },
    dialog: {
      background: colors.blueGrey[800],
      color: colors.common.white,
    },
    form: {
      background: colors.grey[800],
      padding: theme.spacing(1, 2, 1, 2),
      height: '100%',
    },
    formControl: {
      marginTop: theme.spacing(2),
    },
    radioButton: {
      display: 'flex',
      alignItems: 'center',
    },
    helpMessage: {
      background: colors.grey[700],
    },
    typographyHelp: {
      padding: theme.spacing(2),
    },
  })
);

interface LojaInterface {
  id: string;
  nomeFantasia: string;
  cidade: string;
  uf: string;
}

interface UsuarioInterface {
  id: string;
  nome: string;
  email: string;
  administrador: boolean;
  emailConfirmed: boolean;
  lojas: Array<string>;
}

const CssTextField = withStyles((theme) => ({
  root: {
    '& :not(.Mui-error)': {
      '&.Mui-focused.MuiInputLabel-asterisk, &.Mui-focused.MuiInputLabel-outlined': {
        color: theme.palette.primary.main,
      },
      '&.MuiInputLabel-outlined': {
        color: colors.grey[400],
        '&.MuiInputLabel-asterisk': {
          color: colors.grey[400],
        },
      },

      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.grey[300],
          color: colors.grey[300],
        },
        '&:hover fieldset :not(.Mui-disabled)': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& input:disabled': {
          color: colors.grey[200],
          background: '#2e3b42',
        },
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: colors.grey[300],
    },
  },
}))(TextField);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="secondary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

const schema = yup.object().shape({
  nome: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  email: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
  lojas: yup.array().min(1, ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
});

interface PropsDialog {
  usuarioId: string;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDialogClose: () => void;
}

const AlterarUsuario = ({
  usuarioId,
  dialogOpen,
  setDialogOpen,
  handleDialogClose,
}: PropsDialog) => {
  const classes = useStyles();
  const [lojas, setLojas] = useState([] as Array<LojaInterface>);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const [
    anchorElHelpEmailConfirmado,
    setAnchorElHelpEmailConfirmado,
  ] = React.useState(null);

  const [administrador, setAdministrador] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const bloquearDesmarcarAdministrador = auth.usuarioId() === usuarioId;
  const [loading, setLoading] = React.useState(false);
  const [emailConfirmado, setEmailConfirmado] = React.useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    register,
    errors,
    trigger,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      email: '',
      lojas: [] as Array<LojaInterface>,
    },
  });

  const openHelp = Boolean(anchorElHelp);
  const openHelpEmailConfirmado = Boolean(anchorElHelpEmailConfirmado);

  const handleClickOpenHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClickCloseHelp = () => {
    setAnchorElHelp(null);
  };

  const handleClickOpenHelpEmailConfirmado = (event) => {
    setAnchorElHelpEmailConfirmado(event.currentTarget);
  };

  const handleClickCloseHelpEmailConfirmado = () => {
    setAnchorElHelpEmailConfirmado(null);
  };

  const handleCheckAdminsitrador = () => {
    setAdministrador(!administrador);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const getLojas = useCallback(async () => {
    setLoading(true);

    if (lojas.length === 0) {
      const response = await api.get<void, ResponseApi<Array<LojaInterface>>>(
        ConstanteEnderecoWebservice.LOJA_LISTAR,
        {
          params: {
            empresaId: auth.empresaId(),
          },
        }
      );

      if (response.sucesso) {
        setLojas(response.dados);
      }

      if (response.avisos) {
        response.avisos.map((item: string) =>
          enqueueSnackbar(item, { variant: 'warning' })
        );
      }
    }
  }, [enqueueSnackbar, lojas.length]);

  const getUsuario = useCallback(async () => {
    if (lojas.length > 0) {
      const response = await api.get<void, ResponseApi<UsuarioInterface>>(
        ConstanteEnderecoWebservice.USUARIO_OBTER,
        {
          params: {
            usuarioId,
          },
        }
      );

      if (response.sucesso) {
        const usuario = response.dados;

        reset({
          nome: usuario.nome,
          email: usuario.email,
          lojas: lojas.filter((loja) => usuario.lojas.includes(loja.id)),
        });

        setEmailConfirmado(usuario.emailConfirmed);
        setAdministrador(usuario.administrador);
      }

      if (response.avisos) {
        response.avisos.map((item: string) =>
          enqueueSnackbar(item, { variant: 'warning' })
        );
      }

      setLoading(false);
    }
  }, [enqueueSnackbar, lojas, reset, usuarioId]);

  const getDados = useCallback(async () => {
    await getLojas();
    await getUsuario();
  }, [getLojas, getUsuario]);

  const handleVincularTodasLojas = () => {
    setValue('lojas', lojas);
    if (errors.lojas) trigger();
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    let lojasId = [] as Array<any>;

    if (lojas.length > 1) {
      lojasId = data.lojas ? data.lojas.map((item) => item.id) : [];
    } else {
      lojasId = lojas.map((item) => item.id);
    }

    const response = await api.put<void, ResponseApi>(
      ConstanteEnderecoWebservice.USUARIO_ALTERAR,
      {
        id: usuarioId,
        administrador,
        nome: data.nome,
        email: data.email,
        empresaId: auth.empresaId(),
        lojasId,
      }
    );

    setLoading(false);

    if (response.sucesso) {
      enqueueSnackbar('Operação realizada com sucesso.', {
        variant: 'success',
      });

      handleDialogClose();
      setDialogOpen(false);
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }
  });

  useEffect(() => {
    if (dialogOpen) {
      getDados();
    }
  }, [dialogOpen, getDados]);

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.body}
    >
      {loading && <Loading />}
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Alterar usuário
          </Typography>
          <ButtonLoading
            text="Salvar"
            loading={false}
            size="small"
            fullWidth={false}
            variant="text"
            color="secondary"
            cor={colors.common.white}
            onClick={onSubmit}
          />
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item className={classes.radioButton} xs={12} md={2}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      inputRef={register}
                      name="administrador"
                      onChange={handleCheckAdminsitrador}
                      checked={administrador}
                      disabled={bloquearDesmarcarAdministrador}
                    />
                  }
                  style={{ marginRight: '2px' }}
                  label="Administrador"
                />
                <IconButton size="small" onClick={handleClickOpenHelp}>
                  <HelpOutline fontSize="small" />
                </IconButton>
                <Popover
                  classes={{ paper: classes.helpMessage }}
                  open={openHelp}
                  anchorEl={anchorElHelp}
                  onClose={handleClickCloseHelp}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Typography className={classes.typographyHelp}>
                    O usuário administrador é vinculado automaticamente com
                    todas as lojas e possui acesso em todas as funcionalidades
                    do aplicativo.
                  </Typography>
                </Popover>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                required
                variant="outlined"
                margin="normal"
                fullWidth
                label="Nome"
                name="nome"
                autoFocus
                control={control}
                setValue={setValue}
                error={Boolean(errors.nome)}
                helperText={errors.nome?.message}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ display: 'flex' }}>
                <FormInput
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="E-mail"
                  name="email"
                  control={control}
                  setValue={setValue}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  disabled={emailConfirmado}
                />
                {emailConfirmado && (
                  <>
                    <IconButton
                      size="small"
                      onClick={handleClickOpenHelpEmailConfirmado}
                      style={{
                        marginTop: '31px',
                        maxHeight: '26px',
                        paddingLeft: '5px',
                      }}
                    >
                      <HelpOutline fontSize="small" />
                    </IconButton>
                    <Popover
                      classes={{ paper: classes.helpMessage }}
                      open={openHelpEmailConfirmado}
                      anchorEl={anchorElHelpEmailConfirmado}
                      onClose={handleClickCloseHelpEmailConfirmado}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Typography className={classes.typographyHelp}>
                        Não é possível alterar um e-mail já confirmado.
                      </Typography>
                    </Popover>
                  </>
                )}
              </div>
            </Grid>
            {!administrador && lojas.length > 1 && (
              <Grid item xl={12} xs={12}>
                <Button
                  onClick={handleVincularTodasLojas}
                  style={{ float: 'right' }}
                  color="primary"
                >
                  Vincular com todas as lojas
                </Button>
                <FormInputAutoComplete
                  control={control}
                  name="lojas"
                  defaultValue={[]}
                  noOptionsText="Loja não encontrada"
                  options={lojas}
                  getOptionLabel={(option) =>
                    `${option.nomeFantasia} (${option.cidade} - ${option.uf})`
                  }
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.nomeFantasia} (${option.cidade} - ${option.uf})`}
                    </>
                  )}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="outlined"
                      label="Lojas"
                      required
                      error={Boolean(errors.lojas)}
                    />
                  )}
                />
                {Boolean(errors.lojas) && (
                  <FormHelperText className="Mui-error MuiFormHelperText-contained">
                    {ConstanteMensagemValidacao.CAMPO_OBRIGATORIO}
                  </FormHelperText>
                )}
              </Grid>
            )}
          </Grid>

          <div />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AlterarUsuario;
