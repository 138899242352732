import React from 'react';
import { Card, Grid, Container } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import VendaPorVendedorV1 from 'components/Grafico/Loja/VendaPorVendedor/v1/VendaPorVendedor';
import VendaPorVendedorV2 from 'components/Grafico/Loja/VendaPorVendedor/v2/VendaPorVendedor';
import VendaPorHoraV1 from 'components/Grafico/Loja/VendaPorHora/v1/VendaPorHora';
import VendaPorHoraV2 from 'components/Grafico/Loja/VendaPorHora/v2/VendaPorHora';
import VendaPorDiaSemana from 'components/Grafico/Loja/VendaPorDiaSemana';
import VendaLojaPorFormaPagamentoV1 from 'components/Grafico/Loja/VendaLojaPorFormaPagamento/v1/VendaLojaPorFormaPagamento';
import VendaLojaPorFormaPagamentoV2 from 'components/Grafico/Loja/VendaLojaPorFormaPagamento/v2/VendaLojaPorFormaPagamento';

import VendaTicketMedio from 'components/Grafico/Loja/VendaTicketMedio';
import VendaTotalizadoresV1 from 'components/Grafico/Loja/VendaTotalizadores/v1/VendaTotalizadores';
import VendaTotalizadoresV2 from 'components/Grafico/Loja/VendaTotalizadores/v2/VendaTotalizadores';

import { useLayout } from 'store/LayoutContext';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '100%',
    minheight: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    'z-index': '999',
  },
}));

const Vendas: React.FC = () => {
  const classes = useStyles();
  const { setTitlePage } = useLayout();
  const { obterVersaoGrafico } = useChartData();

  React.useEffect(() => {
    setTitlePage('Vendas');
  });

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xl={12} xs={12}>
            <Card
              style={{
                background: '#848884',
              }}
            >
              {obterVersaoGrafico(
                IdentificacaoGrafico.Vendas_VendasPorVendedor
              ) === 1 ? (
                <VendaPorVendedorV1 />
              ) : (
                <VendaPorVendedorV2 />
              )}
            </Card>
          </Grid>
          <Grid item lg={6} md={12} xl={6} xs={12}>
            <Card
              style={{
                background: '#FE6383',
              }}
            >
              {obterVersaoGrafico(IdentificacaoGrafico.Vendas_VendasPorHora) ===
              1 ? (
                <VendaPorHoraV1 />
              ) : (
                <VendaPorHoraV2 />
              )}
            </Card>
          </Grid>
          <Grid item lg={6} md={12} xl={6} xs={12}>
            <Card
              style={{
                background: '#7C66C8',
              }}
            >
              <VendaPorDiaSemana />
            </Card>
          </Grid>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#4B5360',
                height: '100% !important',
              }}
            >
              {obterVersaoGrafico(
                IdentificacaoGrafico.Vendas_VendasPorFormaPagto
              ) === 1 ? (
                <VendaLojaPorFormaPagamentoV1 />
              ) : (
                <VendaLojaPorFormaPagamentoV2 />
              )}
            </Card>
          </Grid>

          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#0B99C1',
                height: '100% !important',
              }}
            >
              <VendaTicketMedio />
            </Card>
          </Grid>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#28D8B2',
                height: '100% !important',
              }}
            >
              {obterVersaoGrafico(IdentificacaoGrafico.Vendas_Totalizadores) ===
              1 ? (
                <VendaTotalizadoresV1 />
              ) : (
                <VendaTotalizadoresV2 />
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Vendas;
