import React from 'react';
import { Card, Grid, Container } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContasReceber from 'components/Grafico/ContasAReceber/ContasAReceber';
import ContasRecebidas from 'components/Grafico/ContasAReceber/ContasRecebidas';

import { useLayout } from 'store/LayoutContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '100%',
    minheight: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    'z-index': '999',
  },
}));

const ContasAPagar: React.FC = () => {
  const classes = useStyles();
  const { setTitlePage } = useLayout();

  React.useEffect(() => {
    setTitlePage('Contas a Receber');
  });

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#4B5360',
                height: '100% !important',
              }}
            >
              <ContasRecebidas />
            </Card>
          </Grid>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#fe6863',
                height: '100% !important',
              }}
            >
              <ContasReceber />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContasAPagar;
