export default function formatarDinheiro(
  valor: any,
  exibirPrefixo: boolean
): string {
  let valorFormatado = Number(valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  if (!exibirPrefixo) {
    valorFormatado = valorFormatado.replace('R$ ', ''); // O espaço gerado pelo toLocaleString é um outro caracter
  }

  return valorFormatado;
}
