import { Day } from 'react-modern-calendar-datepicker';

export default function dateToDayDatePicker(date: Date): Day {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export const formatDate = new Intl.DateTimeFormat('pt-BR', {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
});
