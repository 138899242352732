const ConstanteRotas = {
  LOGIN: '/',
  RECUPERAR_SENHA: '/recuperar-senha',
  REDEFINIR_SENHA: '/redefinir-senha',
  VENDAS_POR_LOJA: '/vendas-por-loja',
  VENDAS_TODAS_LOJAS: '/vendas-todas-lojas',
  PRODUTOS: '/produtos',
  CONTAS_PAGAR: '/contas-pagar',
  CONTAS_RECEBER: '/contas-receber',
  CONFIRMAR_EMAIL: '/confirmar-email',
};

export default ConstanteRotas;

export const SubstituirParametroRota = (
  route: string,
  paramName: string,
  value: string
) => {
  return route.replace(`:${paramName}`, value);
};
