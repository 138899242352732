import React, { useEffect } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import auth from 'auth';

const ServiceWorkerWrapper: React.FC = () => {
  const [showReload, setShowReload] = React.useState(false);

  useEffect(() => {
    window.addEventListener('pwaUpdated', () => {
      setShowReload(true);
    });
  }, []);

  const reloadPage = () => {
    setShowReload(false);
    auth.clearTokenAndRedirect();
  };

  return (
    <Snackbar
      open={showReload}
      message="Uma nova versão do aplicativo está disponível! Você pode atualizar agora ou ele será atualizado automaticamente após reiniciar."
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={10000}
      action={
        <Button color="primary" size="small" onClick={reloadPage}>
          Atualizar
        </Button>
      }
    />
  );
};

export default ServiceWorkerWrapper;
