import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    background: '#993A50',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: '#FE6383',
  },
  dividerInformacaoAdicional: {
    background: '#b8495f',
    marginBottom: '6px',
  },
  descricao: {
    color: (props: any) => props.cor,
    fontSize: '14px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  data: {
    color: (props: any) => props.cor,
    fontSize: '12px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  valorTotal: {
    fontSize: '14px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  total: {
    fontSize: '12px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
  },
}));

const ContasAPagar = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.ContasAPagar_ContasAPagar
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      const items = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          descricao: item.Descricao,
          informacoesAdicionais: item.InformacaoAdicional.map(
            (infoAdicional) => ({
              data: infoAdicional.Descricao,
              total: infoAdicional.Total,
            })
          ),
          valorTotal: item.Total,
        };
      });

      setTotalVendas(informacaoFiltro.Total);
      setLegenda(items);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
          key: index,
        };
      });

      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          const filtroPadrao = filtrosComValor.filter(
            (filtroItem) => filtroItem.valor === 4
          );

          if (filtroPadrao[0]) {
            obterDadosPorFiltro(filtroPadrao[0].valor);
          } else {
            obterDadosPorFiltro(
              filtrosComValor[filtrosComValor.length - 1].valor
            );
          }
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  const InfoGrafico = (props) => {
    const { descricao, valorTotal, informacoesAdicionais } = props;
    const classes = useStyles({ cor: 'white' });

    return (
      <>
        <div className={classes.container}>
          <div className={classes.descricao}>
            <div>{descricao}</div>
            <div className={classes.valorTotal}>
              <p>{formatarDinheiro(valorTotal, true)}</p>
            </div>
          </div>
          {informacoesAdicionais.map((informacaoAdicional) => (
            <>
              <div
                style={{
                  paddingBottom: '5px 0 5px 0',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div className={classes.data}>{informacaoAdicional.data}</div>
                <div className={classes.total}>
                  {formatarDinheiro(informacaoAdicional.total, true)}
                </div>
              </div>
              <Divider className={classes.dividerInformacaoAdicional} />
            </>
          ))}
        </div>
        <Divider className={classes.divider} />
      </>
    );
  };

  return (
    <>
      <HeaderCard
        titulo="Contas a Pagar"
        subtitulo="por mês"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />

      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#FE6383"
        bgNaoSelecionado="#993A50"
        handle={obterDadosPorFiltro}
        opcaoPadrao={4}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total de Contas em Aberto"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            {legenda.map((item) => (
              <InfoGrafico
                key={item.id}
                descricao={item.descricao}
                valorTotal={item.valorTotal}
                informacoesAdicionais={item.informacoesAdicionais}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ContasAPagar;
