import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export interface DadosInterface {
  identificacao: number;
  dataUltimaSincronizacao: Date;
  dados: any;
}

interface LayoutContextProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
  loadingReloadGraficos: boolean;
  setLoadingReloadGraficos: (value: boolean) => void;
  titlePage: string;
  setTitlePage: (value: string) => void;
  appInsights: ApplicationInsights;
}

const LayoutContext = createContext<LayoutContextProps>(
  {} as LayoutContextProps
);

interface LayoutContextProviderProps {
  children: React.ReactNode;
}

export default function LayoutProvider({
  children,
}: LayoutContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [loadingReloadGraficos, setLoadingReloadGraficos] = useState(false);

  const [titlePage, setTitlePage] = useState('Vendas');

  const appInsights = useMemo(
    () =>
      new ApplicationInsights({
        config: {
          instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS, // '749cccae-6a15-41f0-920d-2b254674188d',
        },
      }),
    []
  );

  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     appInsights.loadAppInsights();
  //     appInsights.context.application.ver = process.env.REACT_APP_VERSION
  //       ? process.env.REACT_APP_VERSION
  //       : '';
  //   }
  // }, [appInsights]);

  return (
    <LayoutContext.Provider
      value={{
        loading,
        setLoading,
        titlePage,
        setTitlePage,
        loadingReloadGraficos,
        setLoadingReloadGraficos,
        appInsights,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout(): LayoutContextProps {
  const context = useContext(LayoutContext);

  if (!context)
    throw new Error('useLayout must be used within a LayoutProvider.');

  return context;
}
