import axios, { AxiosRequestConfig } from 'axios';
// import { toast } from 'react-toastify';
import auth from 'auth';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoSistemaEnum from 'constants/enumTipoSistema';

export interface ResponseApi<T = unknown> {
  sucesso: boolean;
  avisos: Array<string>;
  tiposAviso: Array<string>;
  erros: Array<string>;
  dados: T;
}

const sistemaIsZendarOrPowerstock = auth.getSistema() !== TipoSistemaEnum.STI3;

const refreshTokenEnderecoWebService = sistemaIsZendarOrPowerstock
  ? ConstanteEnderecoWebservice.AUTENTICACAO_INTEGRACAO_REFRESH_TOKEN
  : ConstanteEnderecoWebservice.AUTENTICACAO_REFRESH_TOKEN;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'referencia-servico': '',
    HTTP_REFERER_MULTIEMPRESA: '',
  },
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    try {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;

        return api
          .put<void, ResponseApi>(refreshTokenEnderecoWebService, {
            token: auth.getToken(),
            refreshToken: auth.getRefreshToken(),
          })
          .then((res) => {
            if (res.sucesso && res.dados) {
              auth.setToken(res.dados);

              api.defaults.headers.common.Authorization = `Bearer ${auth.getToken()}`;

              return api(originalRequest);
            }

            auth.clearToken();
            window.location.href = '/';

            return api(originalRequest);
          });
      }

      if (error.response?.status === 405 && !originalRequest.retry) {
        originalRequest.retry = true;

        return api(originalRequest);
      }

      if (!originalRequest.retry) {
        originalRequest.retry = true;

        return {
          data: {
            sucesso: false,
          },
          avisos: [
            'O aplicativo está em manutenção, tente novamente mais tarde.',
          ],
        };
      }

      return null;
    } catch (err) {
      return {
        data: {
          sucesso: false,
        },
        avisos: ['Ocorreu um erro inesperado, tente novamente mais tarde.'],
      };
    }
  }
);

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = auth.getToken();
  const dominio = auth.getDataDominio();
  const sistema = auth.getSistema();
  const configNew = config;

  configNew.headers['Access-Control-Allow-Origin'] = '*';
  configNew.headers['referencia-servico'] = 'MODULO_DASHBOARD';

  if (sistema) {
    if (
      process.env.REACT_APP_APP_SISTEMA !== undefined &&
      process.env.NODE_ENV !== 'development'
    ) {
      // Homolog
      configNew.headers.HTTP_REFERER_MULTIEMPRESA = `${dominio}.azurewebsites.net`;
    } else if (
      process.env.REACT_APP_APP_SISTEMA !== undefined &&
      process.env.NODE_ENV === 'development'
    ) {
      // Dev
      configNew.headers.HTTP_REFERER_MULTIEMPRESA = `${dominio}`;
    } else {
      configNew.headers.HTTP_REFERER_MULTIEMPRESA = `${dominio}.${sistema?.name}.app`;
    }
  }

  if (token) {
    configNew.headers.Authorization = `Bearer ${token}`;
  }

  return configNew;
});

export default api;
