import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import formatarDinheiro from 'helpers/moneyFormat';

const useStyles = makeStyles(() => ({
  container: {
    height: '55px',
    background: '#fff',
    marginBottom: '10px',
    display: 'grid',
    verticalAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowValor: {
    color: '#1E2326',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
  },
  rowTitulo: {
    color: '#1E2326',
    fontSize: '16px',
    marginTop: '-15px',
    textAlign: 'center',
  },
}));

const Totalizador = ({ valor, titulo, exibirEmReais = true }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.rowValor}>
        {exibirEmReais && <span style={{ fontSize: '12px' }}>R$ </span>}
        <span>{exibirEmReais ? formatarDinheiro(valor, false) : valor}</span>
      </div>
      <span className={classes.rowTitulo}>{titulo}</span>
    </div>
  );
};

export default Totalizador;
