import React, { useCallback, useEffect, useState } from 'react';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  ListItem,
  makeStyles,
  List,
  Box,
  colors,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  VendasIcon,
  ContasPagarIcon,
  ContasReceberIcon,
  ProdutosIcon,
} from 'icons';

import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import auth from 'auth';
import TipoSistemaEnum from 'constants/enumTipoSistema';

import GerenciarUsuario from 'components/GerenciarUsuario';
import ConstanteRotas from 'constants/rotas';
import TrocarLoja from 'components/TrocarLoja';
import Loading from 'components/Loading';
import Downloads from 'pages/Dashboard/Downloads';

const useStyles = makeStyles((theme) => ({
  list: {
    '& .Mui-selected': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    '& .Mui-selected:hover': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    '& .Mui-selected span': {
      marginLeft: '-4px',
    },
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: (props: any) =>
      props.administrador ? theme.spacing(3) : theme.spacing(1),
    color: '#fff',
    fontSize: '22px',
  },
  iconSair: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: '#fff',
    fontSize: '22px',
  },
  title: {
    marginRight: 'auto',
    fontSize: '16px',
    color: '#fff',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  divSubmenu: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& div': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      paddingLeft: theme.spacing(1),
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(1),
    },
    '& span': {
      backgroundColor: colors.blueGrey[200],
      height: '1px',
      width: '100%',
    },
  },
  divider: {
    background: colors.blueGrey[400],
  },
}));

interface RelatoriosInterface {
  identificacao: number;
  descricao: string;
}

const SubMenu = ({ className, titulo }) => {
  return (
    <div className={className}>
      <div>{titulo}</div>
      <span />
    </div>
  );
};

const NavItem = ({ closeHandle, ...rest }) => {
  const [relatorios, setRelatorios] = useState<RelatoriosInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const administrador = auth.administrador();

  const classes = useStyles({ administrador });
  const history = useHistory();
  const maisUmaLoja = auth.getLojas().length > 1;

  const items = [
    {
      href: '/vendas-por-loja',
      Icon: <VendasIcon className={classes.icon} />,
      title: 'Vendas',
      key: 'vendas',
    },
    {
      href: '/produtos',
      Icon: <ProdutosIcon className={classes.icon} />,
      title: 'Produtos',
      key: 'produtos',
    },
    {
      href: '/contas-pagar',
      Icon: <ContasPagarIcon className={classes.icon} />,
      title: 'Contas a Pagar',
      key: 'contas-pagar',
    },
    {
      href: '/contas-receber',
      Icon: <ContasReceberIcon className={classes.icon} />,
      title: 'Contas a Receber',
      key: 'contas-receber',
    },
  ];

  const [currentPage, setCurrentPage] = useState('todas-lojas');

  const sistema = auth.getSistema() || '';

  const sistemaIsZendarOrPowerstock = sistema.value !== TipoSistemaEnum.STI3;

  const logoffEnderecoWebService = sistemaIsZendarOrPowerstock
    ? ConstanteEnderecoWebservice.AUTENTICACAO_INTEGRACAO_LOGOFF
    : ConstanteEnderecoWebservice.AUTENTICACAO_LOGOFF;

  const logOff = async () => {
    await api.post<void, ResponseApi>(logoffEnderecoWebService);

    auth.clearToken();
    history.push(ConstanteRotas.LOGIN);
  };

  const handleSetPage = (key) => {
    closeHandle();
    setCurrentPage(key);
  };

  const getRelatorios = useCallback(async () => {
    setIsLoading(true);
    const response = await api.get<void, ResponseApi<RelatoriosInterface[]>>(
      ConstanteEnderecoWebservice.RELATORIOS_DISPONIVEIS
    );
    if (response) {
      if (response.sucesso) {
        setRelatorios(response.dados);
      }

      if (response.avisos) {
        response.avisos.forEach((avisos: string) =>
          enqueueSnackbar(avisos, { variant: 'warning' })
        );
      }
    }
    setIsLoading(false);
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (!sistemaIsZendarOrPowerstock) {
      getRelatorios();
    }
  }, [sistemaIsZendarOrPowerstock, getRelatorios]);

  return (
    <>
      <Box>
        {isLoading && <Loading />}

        <List className={classes.list}>
          <>
            <SubMenu titulo="Todas as lojas" className={classes.divSubmenu} />
            <ListItem
              key="todas-lojas"
              className={`${classes.item} ${
                currentPage === 'todas-lojas' ? 'Mui-selected' : ''
              }`}
              disableGutters
              selected={currentPage === 'todas-lojas'}
              {...rest}
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to="/vendas-todas-lojas"
                onClick={() => handleSetPage('todas-lojas')}
                color="secondary"
              >
                <VendasIcon className={classes.icon} />
                <span className={classes.title}>Vendas</span>
              </Button>
            </ListItem>
          </>

          {administrador &&
            !sistemaIsZendarOrPowerstock &&
            relatorios?.length > 0 && (
              <Downloads
                classesItem={classes}
                currentPage={currentPage}
                relatorios={relatorios}
              />
            )}

          <SubMenu titulo="Por loja" className={classes.divSubmenu} />

          <List component="div" disablePadding>
            {items.map((item) => (
              <ListItem
                key={item.key}
                className={`${classes.item} ${
                  currentPage === item.key ? 'Mui-selected' : ''
                }`}
                disableGutters
                selected={currentPage === item.key}
                {...rest}
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={RouterLink}
                  to={item.href}
                  onClick={() => handleSetPage(item.key)}
                  color="secondary"
                >
                  {item.Icon}
                  <span className={classes.title}>{item.title}</span>
                </Button>
              </ListItem>
            ))}
          </List>

          {((administrador && !sistemaIsZendarOrPowerstock) || maisUmaLoja) && (
            <SubMenu titulo="Configurações" className={classes.divSubmenu} />
          )}

          {maisUmaLoja && (
            <TrocarLoja classesItem={classes} currentPage={currentPage} />
          )}

          {administrador && !sistemaIsZendarOrPowerstock && (
            <GerenciarUsuario classesItem={classes} currentPage={currentPage} />
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box>
        <Divider className={classes.divider} />
        <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
          <ListItem key="sair" className={classes.item} disableGutters>
            <Button
              className={classes.button}
              component={RouterLink}
              to="/"
              onClick={logOff}
              color="secondary"
            >
              <ExitToAppIcon className={classes.iconSair} />
              <span className={classes.title}>Sair</span>
            </Button>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default NavItem;
