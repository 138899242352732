import {
  colors,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Zoom from '@material-ui/core/Zoom';
import { UsuarioInterface } from 'components/GerenciarUsuario';
import auth from 'auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperMenu: {
      background: colors.grey[900],
    },
    iconAction: {
      marginRight: theme.spacing(1),
    },
  })
);

interface ButtonMoreProps {
  usuario: UsuarioInterface;
  handleAlterar: (usuario) => void;
  handleExcluir: (usuario) => void;
  handleResetSenha: (usuario) => void;
  handleReenviarEmailConfirmacao: (usuario) => void;
}

const ButtonLoading = ({
  usuario,
  handleAlterar,
  handleExcluir,
  handleResetSenha,
  handleReenviarEmailConfirmacao,
}: ButtonMoreProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const usuarioLogadoId = auth.usuarioId();

  const handleCloseMenuOpcoes = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex' }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="secondary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon color="secondary" aria-haspopup="true" />
      </IconButton>

      <Menu
        classes={{ paper: classes.paperMenu }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuOpcoes}
        TransitionComponent={Zoom}
      >
        <MenuItem
          onClick={() => {
            handleAlterar(usuario.id);
            handleCloseMenuOpcoes();
          }}
        >
          <EditIcon className={classes.iconAction} fontSize="small" />
          Alterar
        </MenuItem>
        {usuarioLogadoId !== usuario.id && (
          <MenuItem
            onClick={() => {
              handleExcluir(usuario);
              handleCloseMenuOpcoes();
            }}
          >
            <DeleteIcon className={classes.iconAction} fontSize="small" />
            Excluir
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleResetSenha(usuario);
            handleCloseMenuOpcoes();
          }}
        >
          <LockIcon className={classes.iconAction} fontSize="small" />
          Alterar senha
        </MenuItem>
        {!usuario.emailConfirmed && (
          <MenuItem
            onClick={() => {
              handleReenviarEmailConfirmacao(usuario);
              handleCloseMenuOpcoes();
            }}
          >
            <EmailIcon className={classes.iconAction} fontSize="small" />
            Reenviar confirmação de e-mail
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ButtonLoading;
