import { Button, colors, PropTypes } from '@material-ui/core';
import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

interface ButtonLoadingProps {
  text: string;
  loading: boolean;
  cor?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  fullWidth?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  className?: string;
}

const ButtonLoading = ({
  text,
  loading,
  cor = colors.teal[400],
  size = 'large',
  fullWidth = true,
  variant = 'contained',
  color = 'primary',
  className,
  ...rest
}: ButtonLoadingProps) => {
  const heightLoader = size === 'large' ? '24px' : '12px';

  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      size={size}
      type="submit"
      disabled={loading}
      className={className}
      {...rest}
    >
      <ScaleLoader
        height={heightLoader}
        width="3px"
        margin="2px"
        radius="0px"
        css="margin-bottom: -10px"
        color={cor}
        loading={loading}
      />
      {!loading ? text : ''}
    </Button>
  );
};

export default ButtonLoading;
