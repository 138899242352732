import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import formatarDinheiro from 'helpers/moneyFormat';

const useStyles = makeStyles(() => ({
  container: {
    height: 30,
    backgroundColor: '#323B40',
    borderRadius: 0,
    margin: '0px 10px 5px 10px',
  },
  divComplete: {
    height: '100%',
    transition: 'width 1s ease-in-out',
  },
  spanNome: {
    color: '#fff',
    float: 'left',
    position: 'relative',
    marginTop: '-25px',
    marginLeft: '15px',
  },
  spanValor: {
    color: '#fff',
    float: 'right',
    position: 'relative',
    marginTop: '-25px',
    marginRight: '15px',
  },
}));

const ProgressBar = ({ ...props }) => {
  const classes = useStyles();
  const { bgcolor, completed, nome, valor, key } = props;
  const [completedState, setCompletedState] = useState(0);

  useEffect(() => {
    setCompletedState(completed);
  }, [completed]);

  return (
    <div className={classes.container} key={key}>
      <div
        className={classes.divComplete}
        style={{ width: `${completedState}%`, background: bgcolor }}
      />
      <span className={classes.spanNome}>{nome}</span>
      <span className={classes.spanValor}>{formatarDinheiro(valor, true)}</span>
    </div>
  );
};

export default ProgressBar;
