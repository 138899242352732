import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { colors } from '@material-ui/core';

const Copyright: React.FC = () => {
  return (
    <>
      <Typography
        variant="body2"
        style={{ color: colors.grey[400] }}
        align="center"
      >
        {'Copyright © '}
        <Link color="inherit" href="https://www.sti3.com.br/">
          STi3 Sistemas
        </Link>{' '}
        {new Date().getFullYear()}.
      </Typography>
    </>
  );
};

export default Copyright;
