import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    background: '#18826B',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: '#28D8B2',
  },
  porcentagem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
  },
  descricao: {
    color: (props: any) => props.cor,
  },
  valor: {
    fontSize: '12px',
  },
}));

const VendaTotalizadores = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) => item.identificacao === IdentificacaoGrafico.Vendas_Totalizadores
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      const items = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          descricao: item.Descricao,
          obs: item.InformacaoAdicional[0].Descricao,
          valor: item.Total,
        };
      });

      setTotalVendas(informacaoFiltro.Total);
      setLegenda(items);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);
    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
          key: index,
        };
      });
      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  const InfoGrafico = (props) => {
    const { descricao, valor, obs } = props;
    const classes = useStyles({ cor: 'white' });

    return (
      <>
        <div className={classes.container}>
          <div
            style={{
              paddingBottom: '5px 0 5px 0',
            }}
          >
            <div className={classes.descricao}>{descricao}</div>
            <div style={{ display: 'flex', marginBottom: '5px' }}>
              <p className={classes.valor}>{obs}</p>
            </div>
          </div>
          <div>
            <div className={classes.porcentagem}>
              <p>{formatarDinheiro(valor, true)}</p>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
      </>
    );
  };
  return (
    <>
      <HeaderCard
        titulo="Totalizadores de Vendas"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#28D8B2"
        bgNaoSelecionado="#18826B"
        handle={obterDadosPorFiltro}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total das Vendas"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            {legenda.map((item) => (
              <InfoGrafico
                key={item.id}
                descricao={item.descricao}
                valor={item.valor}
                obs={item.obs}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default VendaTotalizadores;
