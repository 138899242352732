import React, { useCallback, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { useLayout } from 'store/LayoutContext';
import { useChartData } from 'store/ChartDataContext';

import { colors } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ScaleLoader from 'react-spinners/ScaleLoader';
import NavItem from './NavItem';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
    background: '#2B3238',
    color: '#fff',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#36464E',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: '#2D3A40',
    color: '#fff',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    background: '#36464E',
    height: '100%',
  },

  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: '#fff',
  },
  listItem: {
    background: colors.blueGrey[800],
  },
  iconMenu: {
    fontSize: '25px',
  },
  iconRefresh: {
    fontSize: '25px',
  },
  cabecalhoNomeLoja: {
    display: 'inline-grid',
    background: colors.blueGrey[800],
  },
}));

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { syncData, consultarAposLogin, loja } = useChartData();
  const {
    setLoadingReloadGraficos,
    loadingReloadGraficos,
    titlePage,
  } = useLayout();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const handleGet = useCallback(async () => {
    setLoadingReloadGraficos(true);

    await syncData();

    setLoadingReloadGraficos(false);
  }, [setLoadingReloadGraficos, syncData]);

  useEffect(() => {
    if (!consultarAposLogin) {
      handleGet();
    }
  }, [consultarAposLogin, handleGet]);

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="secondary"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.iconMenu} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {titlePage}
            </Typography>
            {loadingReloadGraficos && (
              <ScaleLoader
                height="15px"
                width="2px"
                radius="0px"
                color={theme.palette.common.white}
                css="padding: 9.5px; margin-top: 7px"
              />
            )}
            {!loadingReloadGraficos && (
              <IconButton color="secondary" onClick={() => handleGet()}>
                <RefreshOutlinedIcon className={classes.iconRefresh} />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <ListItem className={classes.cabecalhoNomeLoja}>
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {loja.NomeFantasia}
              </span>
              <span style={{ fontSize: '12px' }}>{loja.CidadeUf}</span>
            </ListItem>
            <NavItem closeHandle={handleDrawerToggle} />
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </>
  );
};

export default Header;
