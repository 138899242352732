import React, { useState } from 'react';
import {
  Button,
  Dialog,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  colors,
  InputBase,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ClassNameMap, useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import JSZip from 'jszip';
import jsFileDownload from 'js-file-download';
import { BsDownload } from 'react-icons/bs';
import axios from 'axios';

import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoSistemaEnum from 'constants/enumTipoSistema';
import auth from 'auth';
import api, { ResponseApi } from 'services/api';

import Transition from 'components/TransitionDefault';
import Loading from 'components/Loading';

import useStyles from './styles';

type Relatorios = {
  urlArquivo: string;
  nomeArquivo: string;
};

type RelatoriosItem = {
  identificacao: number;
  descricao: string;
};

type DownloadsProps = {
  classesItem: ClassNameMap<
    | 'button'
    | 'icon'
    | 'active'
    | 'title'
    | 'divider'
    | 'list'
    | 'item'
    | 'iconSair'
    | 'divSubmenu'
  >;
  currentPage: string;
  relatorios: RelatoriosItem[];
};

const Downloads = ({
  classesItem,
  currentPage,
  relatorios,
}: DownloadsProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [listRelatorios, setListRelatorios] = useState(relatorios);

  const sistema = auth.getSistema();
  const sistemaIsSti3 = sistema.value === TipoSistemaEnum.STI3;

  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const obterRequestAxios = async (
    url: string,
    nomeArquivo: string,
    zip: any
  ) => {
    const res = await axios.get(url, {
      responseType: 'blob',
    });

    zip.file(`${nomeArquivo}.csv`, res.data);
  };

  async function handleDownload(tipoRelatorio, descricaoRelatorio) {
    setLoading(true);

    const zip = new JSZip();

    const response = await api.get<void, ResponseApi<Relatorios[]>>(
      ConstanteEnderecoWebservice.OBTER_RELATORIO,
      {
        params: {
          tipoRelatorio,
          nomePersonalizado: descricaoRelatorio,
        },
      }
    );
    if (response) {
      if (response.avisos) {
        response.avisos.forEach((avisos) => {
          enqueueSnackbar(avisos, { variant: 'warning' });
        });
      }

      if (response.dados && response.sucesso) {
        try {
          const { dados } = response;
          if (dados.length === 0) {
            enqueueSnackbar('Não há dados para download.', {
              variant: 'info',
            });
            setLoading(false);
            return;
          }
          const axiosList = [] as any[];
          let nomeRelatorio = '';
          dados.forEach(async ({ urlArquivo, nomeArquivo }) => {
            nomeRelatorio = nomeArquivo;
            axiosList.push(obterRequestAxios(urlArquivo, nomeArquivo, zip));
          });
          await Promise.all(axiosList);
          await zip.generateAsync({ type: 'blob' }).then((content) => {
            jsFileDownload(
              content,
              dados?.length > 1
                ? `${descricaoRelatorio}.zip`
                : `${nomeRelatorio}.zip`
            );
          });
        } catch (error) {
          enqueueSnackbar(
            'Ocorreu um erro inesperado ao processar o download.',
            { variant: 'error' }
          );
        }
      }
    }

    setLoading(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const filtrarRelatorio = (filtro) => {
    if (filtro === '') {
      setListRelatorios(relatorios);
      return;
    }
    const listaFiltrada = listRelatorios.filter((relatorio) => {
      return relatorio?.descricao
        ?.toLowerCase()
        ?.includes(filtro.toLowerCase());
    });

    setListRelatorios(listaFiltrada);
  };

  return (
    <>
      {sistemaIsSti3 && (
        <ListItem
          key="downloads"
          className={classesItem.item}
          disableGutters
          selected={currentPage === 'downloads'}
        >
          <Button
            className={classesItem.button}
            onClick={handleClickOpen}
            color="secondary"
          >
            <BsDownload
              size={16}
              style={{
                margin: '0 8px 0 24px',
                lineHeight: '10px',
                minWidth: '20px',
                color: currentPage === 'downloads' ? '#26a69a' : '#fff',
              }}
            />
            <span className={classesItem.title}>Downloads</span>
          </Button>
        </ListItem>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.body}
      >
        {loading && <Loading />}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Relatórios disponíveis
            </Typography>
          </Toolbar>
        </AppBar>
        <Divider style={{ background: colors.grey[700] }} />
        <List className={classes.body} style={{ overflow: 'auto' }}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'buscar' }}
              onChange={(e) => {
                filtrarRelatorio(e.target.value);
              }}
            />
          </div>

          {listRelatorios.map((relatorio) => (
            <>
              <ListItem
                classes={{
                  root: classes.listItem,
                }}
              >
                <ListItemText
                  classes={{
                    root: classes.text,
                  }}
                >
                  <Typography>{relatorio.descricao}</Typography>
                </ListItemText>
                <ListItemSecondaryAction
                  onClick={() =>
                    handleDownload(relatorio.identificacao, relatorio.descricao)
                  }
                  color="secondary"
                  classes={{
                    root: classes.listItemAction,
                  }}
                >
                  <IconButton edge="end" aria-label="download">
                    <BsDownload size={16} color="#26a69a" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ background: colors.grey[700] }} />
            </>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default Downloads;
