import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import formatarDinheiro from 'helpers/moneyFormat';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: 40,
    backgroundColor: '#323B40',
    borderRadius: 0,
    margin: '0px 10px 5px 10px',
    opacity: (props: any) => (props.sincronizado ? 'unset' : '0.6'),
  },
  divComplete: {
    height: '100%',
    transition: 'width 1s ease-in-out',
  },
  spanNome: {
    color: '#fff',
    float: 'left',
    position: 'relative',
    marginTop: '-38px',
    marginLeft: '15px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  spanCidade: {
    color: '#fff',
    float: 'left',
    position: 'relative',
    marginTop: '-18px',
    marginLeft: '15px',
    fontSize: '11px',
    whiteSpace: 'nowrap',
  },
  spanValor: {
    color: '#fff',
    float: 'right',
    position: 'relative',
    marginTop: '-30px',
    marginRight: '15px',
  },
  syncProblemIcon: {
    color: 'rgb(250, 91, 91)',
    float: 'right',
    position: 'relative',
    marginTop: '-32px',
    marginRight: '15px',
    cursor: 'pointer',
  },
}));

const ProgressBar = ({ ...props }) => {
  const { bgcolor, completed, nome, cidade, valor, sincronizado, key } = props;
  const classes = useStyles({ sincronizado });
  const [completedState, setCompletedState] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleTooltipButton = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setCompletedState(completed);
  }, [completed]);

  return (
    <div className={classes.container} key={key}>
      <div
        className={classes.divComplete}
        style={{ width: `${completedState}%`, background: bgcolor }}
      />
      <div>
        <span className={classes.spanNome}>{nome}</span>
        <span className={classes.spanCidade}>{cidade}</span>
      </div>
      {sincronizado ? (
        <>
          <span className={classes.spanValor}>
            {formatarDinheiro(valor, true)}
          </span>
        </>
      ) : (
        <Tooltip
          open={open}
          title={
            <span style={{ fontSize: '11px', marginTop: '-450px !important' }}>
              Sincronização pendente
            </span>
          }
          placement="left"
        >
          <SyncDisabledIcon
            className={classes.syncProblemIcon}
            onClick={handleTooltipButton}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ProgressBar;
