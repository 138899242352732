import React, { useCallback, useState, useRef } from 'react';
import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { Button, colors } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useSnackbar } from 'notistack';

import auth from 'auth';
import api, { ResponseApi } from 'services/api';
import { useChartData } from 'store/ChartDataContext';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import TipoSistemaEnum from 'constants/enumTipoSistema';

import { LojaIcon } from 'icons';
import DialogConfirm from 'components/DialogConfirm';
import ButtonLoading from 'components/Button/ButtonLoading';
import Transition from 'components/TransitionDefault';
import Loading from 'components/Loading';

import ButtonOptionsLoja from './ButtonExcluir';

interface LojaInterface {
  id: string;
  nomeFantasia: string;
  cidade: string;
  uf: string;
  dataHoraUltimaSincronizacao: string;
  lojaIdExterno: string;
}

interface InfoLojaProps {
  empresaId: string;
  listaLojasIdExterno: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      background: theme.palette.primary.main,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    body: {
      background: colors.grey[800],
      height: '100%',
      '& .Mui-selected': {
        background: colors.grey[700],
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '& .Mui-selected:hover': {
        background: colors.grey[700],
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '& .Mui-selected p': {
        marginLeft: '-4px',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: theme.spacing(6),
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      'z-index': '999',
    },
  })
);

const TrocarLoja = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const [lojaSelecionada, setLojaSelecionada] = useState('');
  const [lojas, setLojas] = useState<LojaInterface[]>([]);
  const { setLoja, loja, syncData } = useChartData();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const infoLoja = useRef<InfoLojaProps | null>(null);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const lojasAuth = auth.getLojas();
  const userIsAdm = auth.administrador();
  const sistema = auth.getSistema();
  const dominio = auth.getDataDominio();

  const { classesItem, currentPage } = props;
  const sistemaIsSti3 = sistema.value === TipoSistemaEnum.STI3;

  const getLojas = useCallback(async () => {
    setLoading(true);
    const response = await api.get<void, ResponseApi<Array<LojaInterface>>>(
      ConstanteEnderecoWebservice.LOJA_LISTAR,
      {
        params: {
          empresaId: auth.empresaId(),
        },
      }
    );

    setLoading(false);
    if (response.sucesso) {
      setLojas(response.dados);
    }

    if (response.avisos) {
      response.avisos.forEach((aviso: string) =>
        enqueueSnackbar(aviso, { variant: 'warning' })
      );
    }
  }, [enqueueSnackbar]);

  const handleClose = () => {
    setOpen(false);
  };

  const filtrarLojas = (filtro) => {
    const listaFiltrada = lojas.filter((lojaItem) => {
      return (
        filtro === '' ||
        lojaItem.nomeFantasia.toLowerCase().includes(filtro.toLowerCase()) ||
        lojaItem.uf.toLowerCase().includes(filtro.toLowerCase())
      );
    });

    setLojas(listaFiltrada);
  };

  const handleSalvar = async () => {
    setLoading(true);

    const lojaSelecionadaObj = lojasAuth.filter((lojaItem) => {
      return lojaItem.LojaId === lojaSelecionada;
    })[0];

    const sincronizado = await syncData(lojaSelecionada);

    if (sincronizado) {
      setLoja(lojaSelecionadaObj);
      handleClose();
    }
    setLoading(false);
  };

  const handleClickOpen = () => {
    setLojaSelecionada(loja.LojaId);
    setOpen(true);
    getLojas();
  };

  const sincronizacaoAtrasada = (dataSincronizacao: Date) => {
    const dataSincronizacaoNormalizada = new Date(
      dataSincronizacao.getFullYear(),
      dataSincronizacao.getMonth(),
      dataSincronizacao.getDate(),
      0,
      0,
      0
    );

    const dataAtual = new Date();

    const dataAtualNormalizada = new Date(
      dataAtual.getFullYear(),
      dataAtual.getMonth(),
      dataAtual.getDate(),
      0,
      0,
      0
    );

    return dataSincronizacaoNormalizada < dataAtualNormalizada;
  };

  async function handleRemoveLoja() {
    setLoading(true);
    setDialogOpen(false);
    const { empresaId, listaLojasIdExterno } = infoLoja.current
      ? infoLoja?.current
      : ({} as InfoLojaProps);

    const response = await api.post<void, ResponseApi<string>>(
      ConstanteEnderecoWebservice.LOJA_DESATIVAR,
      {
        empresaId,
        listaLojasIdExterno,
      }
    );

    if (response.avisos) {
      response.avisos.forEach((aviso) => {
        enqueueSnackbar(aviso, { variant: 'warning' });
      });
    }

    if (response.sucesso && response.dados) {
      const newLojas = lojas.filter((item) => item.id !== empresaId);
      infoLoja.current = null;
      setLojas(newLojas);
    }
    setLoading(false);
  }

  function onConfirmExclusion(
    empresaId: string,
    listaLojasIdExterno: string[]
  ) {
    infoLoja.current = {
      empresaId,
      listaLojasIdExterno,
    };
  }

  return (
    <>
      <ListItem
        key="gerenciar-lojas"
        className={classesItem.item}
        disableGutters
        selected={currentPage === 'gerenciar-lojas'}
      >
        <Button
          className={classesItem.button}
          onClick={handleClickOpen}
          color="secondary"
        >
          <LojaIcon className={classesItem.icon} />
          <span className={classesItem.title}>Trocar loja</span>
        </Button>
      </ListItem>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.body}
      >
        {loading && <Loading />}

        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Trocar loja
            </Typography>
            <ButtonLoading
              text="Confirmar"
              onClick={handleSalvar}
              loading={false}
              size="small"
              fullWidth={false}
              variant="text"
              color="secondary"
              cor={colors.common.white}
            />
          </Toolbar>
        </AppBar>

        <List className={classes.body} style={{ overflow: 'auto' }}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'buscar' }}
              onChange={(e) => {
                filtrarLojas(e.target.value);
              }}
            />
          </div>

          <Divider style={{ background: colors.grey[700] }} />

          {lojas.map((lojaItem) => (
            <React.Fragment key={lojaItem.id}>
              <ListItem
                selected={lojaSelecionada === lojaItem.id}
                button
                onClick={() => setLojaSelecionada(lojaItem.id)}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: '18px' }}>
                      {lojaItem.nomeFantasia}
                    </Typography>
                    <Typography style={{ fontSize: '12px' }}>
                      {`${lojaItem.cidade} - ${lojaItem.uf}`}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: sincronizacaoAtrasada(
                          new Date(lojaItem.dataHoraUltimaSincronizacao)
                        )
                          ? '#ff9800'
                          : '#fff',
                      }}
                    >
                      {/* {lojaItem.CidadeUf} */}
                      {`Sincronizado em: ${new Date(
                        lojaItem.dataHoraUltimaSincronizacao
                      ).toLocaleString()}`}
                    </Typography>
                  </div>
                  {userIsAdm && sistemaIsSti3 && (
                    <div>
                      <ButtonOptionsLoja
                        onConfirm={() => {
                          onConfirmExclusion(lojaItem.id, [
                            lojaItem.lojaIdExterno,
                          ]);
                        }}
                        setDialogOpen={setDialogOpen}
                      />
                    </div>
                  )}
                </div>
              </ListItem>
              <Divider style={{ background: colors.grey[700] }} />
            </React.Fragment>
          ))}
        </List>
      </Dialog>
      <DialogConfirm
        title="Excluir loja"
        contentText="Todas as informações da loja serão excluídas, caso queira adicionar novamente ative o módulo no powerstock/powerchef. Deseja continuar?"
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
        handleOK={handleRemoveLoja}
      />
    </>
  );
};

export default TrocarLoja;
