import React from 'react';
import { Controller } from 'react-hook-form';
import {
  colors,
  FormHelperText,
  makeStyles,
  NativeSelect,
  withStyles,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(() => ({
  inputLabelProps: {
    color: colors.grey[400],
  },
  option: {
    backgroundColor: `${colors.blueGrey[700]} !important`,
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    '& :not(.Mui-error)': {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },

      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.grey[300],
          color: colors.grey[300],
        },
        '&:hover fieldset :not(.Mui-disabled)': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
        '& input:disabled': {
          color: 'red',
          background: '#2e3b42',
        },
      },
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: colors.grey[300],
    },
  },
}))(FormControl);

interface Props {
  name: string;
  label: string;
  control: any;
  error?: any;
  messageError?: string;
  labelWidth: number;
  optionsList: Array<PropsOptionList> | undefined;
  autoFocus?: boolean;
}

interface PropsOptionList {
  value: string;
  description: string;
}

const FormInputSelect = (props: Props) => {
  const classes = useStyles();

  const {
    name,
    label,
    control,
    error,
    messageError,
    labelWidth,
    optionsList,
    autoFocus,
  } = props;

  const Input = (
    <CssTextField variant="outlined" fullWidth>
      <InputLabel className={classes.inputLabelProps}>{label}</InputLabel>
      <Controller
        as={
          <NativeSelect
            autoFocus={autoFocus}
            input={
              <OutlinedInput
                labelWidth={labelWidth}
                name="age"
                id="outlined-age-always-notched"
                value=""
              />
            }
          >
            <option aria-label="Nenhum" value="" className={classes.option} />
            {optionsList &&
              optionsList.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  className={classes.option}
                >
                  {item.description}
                </option>
              ))}
          </NativeSelect>
        }
        name={name}
        control={control}
      />

      {error && <FormHelperText>{messageError}</FormHelperText>}
    </CssTextField>
  );

  return (
    <Controller
      as={Input}
      name={name}
      control={control}
      label={label}
      fullWidth
      margin="normal"
      variant="outlined"
      error={error}
    />
  );
};

export default FormInputSelect;
