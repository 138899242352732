/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from 'components/Logo';
import { useSnackbar } from 'notistack';
import Copyright from 'components/Copyright';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import api, { ResponseApi } from 'services/api';
import ConstanteRotas from 'constants/rotas';
import ScaleLoader from 'react-spinners/ScaleLoader';
import auth from 'auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  typography: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const ConfirmarEmail: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const confirmarEmail = useCallback(async () => {
    setLoading(true);

    const query = new URLSearchParams(location.search);

    let email = query.get('email');
    email = decodeURIComponent(email as string);

    let token = query.get('token');

    token = decodeURIComponent(token as string);

    const response = await api.put<void, ResponseApi<boolean>>(
      ConstanteEnderecoWebservice.USUARIO_CONFIRMAR_EMAIL,
      { token, email }
    );

    setLoading(false);

    if (response.sucesso) {
      enqueueSnackbar('Seu e-email foi confirmado com sucesso.', {
        variant: 'success',
      });
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }

    history.push(ConstanteRotas.LOGIN);
  }, [enqueueSnackbar, history, location.search]);

  useEffect(() => {
    confirmarEmail();
  }, [confirmarEmail]);

  return (
    <>
      {loading && (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Logo />
            <Typography variant="h6" className={classes.typography}>
              Estamos confirmando o seu e-mail.
            </Typography>
            <Typography variant="h6" className={classes.typography}>
              Aguarde...
            </Typography>
            <ScaleLoader
              height="40px"
              radius="0px"
              color={theme.palette.primary.main}
            />
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      )}
    </>
  );
};

export default ConfirmarEmail;
