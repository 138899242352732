import { colors, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
      body: {
        backgroundColor: '#36464E',
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.MuiDialog-paper': {
        background: colors.blueGrey[900],
        color: colors.common.white,
        margin: 0,
      },
      '.MuiAutocomplete-listbox': {
        backgroundColor: colors.grey[700],
      },
      '.MuiAutocomplete-option': {
        backgroundColor: colors.grey[700],
      },
      '.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: colors.grey[700],
      },
      '.MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: colors.grey[600],
      },
      '.MuiAutocomplete-option:active': {
        backgroundColor: colors.grey[600],
      },
      '.MuiAutocomplete-noOptions': {
        backgroundColor: colors.grey[700],
        color: colors.common.white,
        padding: '14px 16px',
      },
      '.MuiAutocomplete-paper': {
        backgroundColor: colors.grey[700],
      },
      '.DatePicker': {
        zIndex: 'unset!important',
      },
      '.DatePicker__calendarContainer': {
        zIndex: '10!important',
      },
      '.MuiCard-root': {
        overflow: 'unset',
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
