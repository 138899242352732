/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Logo from 'components/Logo';
import {
  colors,
  Container,
  Box,
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { useForm } from 'react-hook-form';
import FormInput from 'components/FormInput';
import ButtonLoading from 'components/Button/ButtonLoading';
import auth from 'auth';
import Copyright from 'components/Copyright';
import FormInputPassword from 'components/FormInputPassword';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import { useSnackbar } from 'notistack';
import { useChartData } from 'store/ChartDataContext';
import ConstanteRotas from 'constants/rotas';
import TipoSistemaEnum from 'constants/enumTipoSistema';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dialog: {
    background: colors.blueGrey[800],
    color: '#fff',
  },
}));

const schema = yup.object().shape({
  email: yup.lazy((_, params) => {
    const { isSti3 } = params.parent;
    if (isSti3) {
      return yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
        .email(ConstanteMensagemValidacao.EMAIL_INVALIDO);
    }
    return yup.string();
  }),
  usuario: yup.lazy((_, params) => {
    const { isSti3 } = params.parent;
    if (!isSti3) {
      return yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }
    return yup.string();
  }),
  senha: yup.string().required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO),
  dominio: yup.lazy((_, params) => {
    const { isSti3 } = params.parent;
    if (!isSti3) {
      return yup
        .string()
        .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO);
    }
    return yup.string();
  }),
  lembrarSenha: yup.boolean(),
});

const rememberLogin = auth.getRememberLogin();

interface RetornoLogin {
  token: string;
  refreshToken: string;
  validadeToken: Date;
  validadeRefreshToken: Date;
  versaoAplicativoDesatualizada: boolean;
}

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { setConsultarAposLogin } = useChartData();

  const sistema = auth.getSistema() || '';

  const sistemaIsZendarOrPowerstock = sistema.value !== TipoSistemaEnum.STI3;

  const loginEnderecoWebService = sistemaIsZendarOrPowerstock
    ? ConstanteEnderecoWebservice.AUTENTICACAO_INTEGRACAO_LOGIN
    : ConstanteEnderecoWebservice.AUTENTICACAO_LOGIN;

  const logoffEnderecoWebService = sistemaIsZendarOrPowerstock
    ? ConstanteEnderecoWebservice.AUTENTICACAO_INTEGRACAO_LOGOFF
    : ConstanteEnderecoWebservice.AUTENTICACAO_LOGOFF;

  const { handleSubmit, errors, control, register, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: rememberLogin,
  });

  const tratarResponse = (response: ResponseApi<RetornoLogin>, dominio) => {
    if (response.sucesso) {
      auth.setAplicacaoDesatualizada(
        response.dados.versaoAplicativoDesatualizada
      );

      // Forçar atualização Safari < 13.0
      if (auth.getAplicacaoDesatualizada().count > 1) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (const registration of registrations) {
              registration.update();
            }
          });
        }
      }

      setConsultarAposLogin(false);
      auth.setToken(response.dados);

      history.push(ConstanteRotas.VENDAS_TODAS_LOJAS);

      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }

    setLoading(false);
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    auth.setDominio(data?.dominio);

    if (data.lembrarSenha) {
      auth.setRememberLogin(data);
    } else {
      auth.removeRememberLogin();
    }

    const dataLogin = {
      ...data,
      dominio: `${data?.dominio}.${sistema.name}.app`,
      versaoAplicativo: process.env.REACT_APP_VERSION,
    };

    const response = await api.post<void, ResponseApi<RetornoLogin>>(
      loginEnderecoWebService,
      dataLogin
    );

    setLoading(false);

    if (response.sucesso) {
      tratarResponse(response, data?.dominio);
      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }
  });

  const logOff = useCallback(async () => {
    await api.post<void, ResponseApi>(logoffEnderecoWebService);

    auth.clearToken();
  }, [logoffEnderecoWebService]);

  React.useEffect(() => {
    if (auth.autenticado()) {
      logOff();
    }
  }, [logOff]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'full',
        height: '100vh',
      }}
    >
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Logo />
          <form onSubmit={onSubmit} className={classes.form}>
            {sistemaIsZendarOrPowerstock && (
              <FormInput
                required
                variant="outlined"
                margin="normal"
                fullWidth
                label="Domínio"
                name="dominio"
                autoComplete="dominio"
                autoFocus
                control={control}
                setValue={setValue}
                error={Boolean(errors.dominio)}
                helperText={errors.dominio?.message}
              />
            )}
            {sistemaIsZendarOrPowerstock ? (
              <FormInput
                required
                variant="outlined"
                margin="normal"
                fullWidth
                label="Usuário"
                name="usuario"
                autoComplete="usuario"
                autoFocus
                control={control}
                setValue={setValue}
                error={Boolean(errors.usuario)}
                helperText={errors.email?.usuario}
              />
            ) : (
              <FormInput
                required
                variant="outlined"
                margin="normal"
                fullWidth
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                control={control}
                setValue={setValue}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  inputRef={register}
                  name="isSti3"
                  defaultChecked={!sistemaIsZendarOrPowerstock}
                />
              }
              label=""
              style={{ color: '#fff', display: 'none' }}
            />
            <FormInputPassword
              required
              name="senha"
              label="Senha"
              autoComplete="current-password"
              control={control}
              error={Boolean(errors.senha)}
              messageError={errors.senha?.message}
              labelWidth={56}
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  inputRef={register}
                  name="lembrarSenha"
                  defaultChecked={rememberLogin.lembrarSenha}
                />
              }
              label="Lembrar login?"
              style={{ color: '#fff' }}
            />
            <ButtonLoading
              text="Entrar"
              loading={loading}
              className={classes.submit}
            />
            <Grid container>
              <Grid item lg>
                <Link
                  onClick={() => history.push('/recuperar-senha')}
                  variant="body2"
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                >
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
          <Typography
            variant="body2"
            style={{ color: colors.grey[400] }}
            align="center"
          >
            Versão {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
