import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import ProgressBar from 'components/ProgressBar';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import Totalizador from 'components/Totalizador';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';

const VendaPorVendedor = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [progressBarList, setProgressBarList] = useState([] as any);
  const [valorTotal, setValorTotal] = useState(0);

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.Vendas_VendasPorVendedor
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const calcularPorcentagens = (list, total) => {
    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    setProgressBarList(
      listPorcentagem.sort(
        (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
      )
    );
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      const informacao = obterDadosGrafico(chartData);

      if (!informacao) {
        setProgressBarList(undefined);
        return;
      }

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      setValorTotal(informacaoFiltro.Total);

      const Itens = informacaoFiltro.Dados.map((item, index) => {
        return { id: index, nome: item.Descricao, valor: item.Total };
      });

      calcularPorcentagens(Itens, informacaoFiltro.Total);
    },
    [chartData, obterDadosGrafico]
  );

  const handleOrdem = (ordem: string) => {
    const list =
      ordem === 'desc'
        ? progressBarList.sort(
            (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
          )
        : progressBarList.sort(
            (a, b) => parseFloat(a.porcentagem) - parseFloat(b.porcentagem)
          );

    setProgressBarList([...list]);
  };

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setProgressBarList([]);
    setValorTotal(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );

      const filtros = informacao.dados.map((item) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
        };
      });

      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  return (
    <div style={{ paddingBottom: '5px' }}>
      <HeaderCard
        titulo="Por Vendedores"
        atualizadoEm={dataUltimaSincronizacao}
        handleOrdem={handleOrdem}
        exibirFiltro={false} // TODO rever se é necessário
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#848884"
        bgNaoSelecionado="#414241"
        handle={obterDadosPorFiltro}
      />

      <Totalizador valor={valorTotal} titulo="Total das Vendas" />

      {progressBarList?.length === 0 && <GraficoSemDados />}

      {progressBarList &&
        progressBarList.map((item) => (
          <ProgressBar
            key={`${item.id}`}
            bgcolor="#1E2326"
            valor={item.valor}
            nome={item.nome}
            completed={item.porcentagem}
          />
        ))}
    </div>
  );
};

export default VendaPorVendedor;
