/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';

const VendaPorDiaSemana = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [total, setTotal] = useState(0);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.Vendas_VendasPorDiaDaSemana
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const atualizarGrafico = (list) => {
    const listSort = list.sort(
      (a, b) => parseFloat(a.hora) - parseFloat(b.hora)
    );

    const infoGrafico = {
      series: [
        {
          name: 'Vendas',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          id: 'venda-por-dia-semana',
          toolbar: {
            show: false,
          },
          foreColor: '#fff',
        },
        colors: ['#00F5F7'],
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: listSort.map((item) => item.descricao),
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
          },
        },
        grid: {
          borderColor: '#fff',
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          min: 0,
          title: {
            style: {
              color: '#fff',
            },
          },
          labels: {
            show: true,
            style: {
              colors: ['#fff'],
            },
            formatter: (value) => {
              return `${parseInt(value)}`;
            },
          },
        },
        labels: [],
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: false,
          },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: [{ name: 'Vendas', data: listSort.map((item) => item.valor) }],
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec('reactchart-example', 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      setInfoGraficoState(undefined);

      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      setTotal(informacaoFiltro.Total);

      const Itens = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          descricao: item.Descricao,
          valor: parseInt(item.Total),
        };
      });

      atualizarGrafico(Itens);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotal(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);
    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
          key: index,
        };
      });
      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  return (
    <>
      <HeaderCard
        titulo="Por Dia da Semana"
        subtitulo="por mês"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#7C66C8"
        bgNaoSelecionado="#4B3D7B"
        handle={obterDadosPorFiltro}
      />

      <Totalizador
        valor={total}
        titulo="Quantidade de Vendas"
        exibirEmReais={false}
      />

      <div style={{ marginBottom: '5px' }}>
        {total === 0 && <GraficoSemDados />}
        {total > 0 && (
          <ReactApexChart
            options={infoGraficoState.options}
            series={infoGraficoState.series}
            type="bar"
            height="250px"
          />
        )}
      </div>
    </>
  );
};

export default VendaPorDiaSemana;
