/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from 'components/Logo';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ConstanteMensagemValidacao from 'constants/mensagensValidacoes';
import { useForm } from 'react-hook-form';
import FormInput from 'components/FormInput';
import ButtonLoading from 'components/Button/ButtonLoading';
import { useSnackbar } from 'notistack';
import Copyright from 'components/Copyright';
import api, { ResponseApi } from 'services/api';
import ConstanteEnderecoWebservice from 'constants/enderecoWebservice';
import ConstanteRotas from 'constants/rotas';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  typography: {
    marginTop: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .required(ConstanteMensagemValidacao.CAMPO_OBRIGATORIO)
    .email(ConstanteMensagemValidacao.EMAIL_INVALIDO),
});

const RecuperarSenha: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);

    const response = await api.post<void, ResponseApi<string>>(
      `${ConstanteEnderecoWebservice.AUTENTICACAO_RECUPERAR_SENHA}${data.email}`
    );

    setLoading(false);

    if (response.sucesso) {
      enqueueSnackbar('E-mail para recuperação de senha enviado com sucesso.', {
        variant: 'success',
      });
      history.push(ConstanteRotas.LOGIN);
      return;
    }

    if (response.avisos) {
      response.avisos.map((item: string) =>
        enqueueSnackbar(item, { variant: 'warning' })
      );
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'full',
        height: '100vh',
      }}
    >
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Logo />
          <Typography variant="h6" className={classes.typography}>
            Esqueceu a senha?
          </Typography>
          <Typography variant="body1" className={classes.typography}>
            Informe o endereço de e-mail que você utilizou no cadastro para
            receber instruções sobre como redefinir sua senha.
          </Typography>
          <form onSubmit={onSubmit} className={classes.form}>
            <FormInput
              required
              variant="outlined"
              margin="normal"
              fullWidth
              label="E-mail"
              name="email"
              autoFocus
              control={control}
              setValue={setValue}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />

            <ButtonLoading
              text="Enviar e-mail"
              loading={loading}
              className={classes.submit}
            />
            <Grid container>
              <Grid item lg>
                <Link
                  onClick={() => history.push(ConstanteRotas.LOGIN)}
                  variant="body2"
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                >
                  Lembrei a senha!
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default RecuperarSenha;
