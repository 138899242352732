import React from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

const FormInputAutoComplete = ({
  options,
  getOptionLabel,
  control,
  defaultValue,
  name,
  renderOption,
  renderInput,
  noOptionsText,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          limitTags={3}
          noOptionsText={noOptionsText}
          disableCloseOnSelect
          multiple
          color="secondary"
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export default FormInputAutoComplete;
