import React from 'react';
import { Card, Grid, Container } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ProdutoSituacao from 'components/Grafico/Produto/ProdutoSituacao';
import ProdutoMaisVendidosV1 from 'components/Grafico/Produto/ProdutoMaisVendidos/v1/ProdutoMaisVendidos';
import ProdutoMaisVendidosV2 from 'components/Grafico/Produto/ProdutoMaisVendidos/v2/ProdutoMaisVendidos';
import ProdutoEmEstoque from 'components/Grafico/Produto/ProdutoEmEstoque';

import { useLayout } from 'store/LayoutContext';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import TotalGruposVendidos from 'components/Grafico/Produto/TotalGruposVendidos';
import auth from 'auth';
import TipoSistemaEnum from 'constants/enumTipoSistema';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '100%',
    minheight: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    'z-index': '999',
  },
}));

const Produtos: React.FC = () => {
  const classes = useStyles();
  const { setTitlePage } = useLayout();
  const { obterVersaoGrafico } = useChartData();

  const sistema = auth.getSistema() || '';
  const sistemaIsZendarOrPowerstock = sistema.value !== TipoSistemaEnum.STI3;

  React.useEffect(() => {
    setTitlePage('Produtos');
  });

  return (
    <>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          {!sistemaIsZendarOrPowerstock && (
            <Grid item xl={6} xs={12}>
              <Card
                style={{
                  background: '#4B5360',
                  height: '100% !important',
                }}
              >
                <ProdutoSituacao />
              </Card>
            </Grid>
          )}
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#FE6383',
                height: '100% !important',
              }}
            >
              {obterVersaoGrafico(
                IdentificacaoGrafico.Produtos_ProdutosMaisVendidos
              ) === 1 ? (
                <ProdutoMaisVendidosV1 />
              ) : (
                <ProdutoMaisVendidosV2 />
              )}
            </Card>
          </Grid>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#0B99C1',
                height: '100% !important',
              }}
            >
              <ProdutoEmEstoque />
            </Card>
          </Grid>
          <Grid item xl={6} xs={12}>
            <Card
              style={{
                background: '#7C66C8',
                height: '100% !important',
              }}
            >
              {obterVersaoGrafico(
                IdentificacaoGrafico.Produtos_TotalGruposVendidos
              ) === 2 ? (
                <TotalGruposVendidos />
              ) : (
                <></>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Produtos;
