import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import GraficoSemDados from 'components/GraficoSemDados';
import LegendaGrafico from 'components/LegendaGrafico';
import Totalizador from 'components/Totalizador';

const VendaPorOrigem = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [infoGraficoState, setInfoGraficoState] = useState<any>(undefined);
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao ===
        IdentificacaoGrafico.TodasEmpresas_VendasPorOrigem
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const atualizarGrafico = (list, total) => {
    const colors = [
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#008FFB',
      '#4CAF50',
      '#F9CE1D',
      '#03A9F4',
      '#FF9800',
      '#33B2DF',
      '#7D02EB',
    ];

    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    const listSort = listPorcentagem.sort(
      (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
    );

    setLegenda(
      listSort.map((item, index) => ({
        cor: colors[index <= colors.length - 1 ? index : index % colors.length],
        formaPagto: item.nome,
        valor: item.valor,
        porcentagem: `${item.porcentagem.toFixed(1)}%`,
      }))
    );

    const infoGrafico = {
      series: listSort.map((item) => item.porcentagem),

      options: {
        stroke: {
          lineCap: 'round',
        },
        chart: {
          id: 'vendas-por-origem',
        },
        colors,
        labels: listSort.map((item) => item.nome),
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          labels: {
            colors: '#fff',
            useSeriesColors: false,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => `${val.toFixed(1)}%`,
          },
        },
      },
    };

    const newInfoGrafico = {
      ...infoGrafico,
      series: listSort.map((item) => item.porcentagem),
      options: {
        ...infoGrafico.options,
        labels: listSort.map((item) => item.nome),
      },
    };

    // Necessário para atualização do gráfico
    try {
      ApexCharts.exec(
        newInfoGrafico.options.chart.id,
        'updateOptions',
        newInfoGrafico.options
      );

      ApexCharts.exec(newInfoGrafico.options.chart.id, 'resetSeries');

      // eslint-disable-next-line no-empty
    } catch (error) {}

    setInfoGraficoState(newInfoGrafico);
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      setInfoGraficoState(undefined);

      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      const Itens = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          nome: item.Descricao,
          valor: item.ValorTotal,
        };
      });

      setTotalVendas(informacaoFiltro.ValorTotal);
      atualizarGrafico(Itens, informacaoFiltro.ValorTotal);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);
    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.ValorTotal > 0,
          key: index,
        };
      });
      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      setRadioButtonList([{}]);
      setTotalVendas(0);
    }
  }, [chartData, obterDadosPorFiltro]);

  return (
    <>
      <HeaderCard
        titulo="Por Origem"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#7C66C8"
        bgNaoSelecionado="#4B3D7B"
        handle={obterDadosPorFiltro}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Quantidade de Vendas"
        exibirEmReais={false}
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            <ReactApexChart
              options={infoGraficoState.options}
              series={infoGraficoState.series}
              type="donut"
              height="300px"
            />
            {legenda.map((item) => (
              <LegendaGrafico
                key={item.formaPagto}
                cor={item.cor}
                descricao={item.formaPagto}
                valor={item.valor}
                porcentagem={item.porcentagem}
                corFundo="#7C66C8"
                corPrincipal="#4B3D7B"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default VendaPorOrigem;
