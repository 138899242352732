import React from 'react';
import { CSSProperties } from 'styled-components';

import GenIcon from './GenIcon';

interface IconInterface extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export type IconType = (props: IconInterface) => JSX.Element;

const Stroke: CSSProperties = {
  fill: 'none',
  stroke: 'currentColor',
};

const StrokeRounded: CSSProperties = {
  ...Stroke,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};

const Stroke075Rounded: CSSProperties = {
  ...StrokeRounded,
  strokeWidth: '0.75px',
};

export const ProdutosIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <g>
        <polyline points="3.6 5.4 0.7 9.6 3.1 10.5 3.1 17.5 11.6 20.2 20 17.6 20 10.5 22.3 9.7 19.9 5.5" />
        <polyline points="3.1 10.5 9 12.3 11.6 8 14 12.3 20 10.5" />
        <polygon points="3.6 5.4 11.7 2.8 19.6 5.4 11.6 8 3.6 5.4" />
      </g>
      <line x1="11.6" y1="20.2" x2="11.6" y2="8" />
    </g>
  </GenIcon>
);

export const PowerStockLogotipo: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 164.6 22" size={size} {...props}>
    <g>
      <polygon fill="#fcd27c" points=".2 9.3 2.1 7.9 2.8 10.2 .2 13 .2 9.3" />
      <polygon fill="#f69f56" points="4.6 6 5.5 7.3 2.8 10.2 2.1 7.9 4.6 6" />
      <polygon fill="#ea3b5a" points="11.9 .2 2.8 3.6 5.5 7.3 11.9 .2" />
      <polygon fill="#f26c3f" points="2.1 7.9 1 4.1 2.8 3.6 4.6 6 2.1 7.9" />
      <polygon fill="#f6905b" points=".2 4.2 1 4.1 2.1 7.9 .2 9.3 .2 4.2" />
      <polygon
        fill="#d71572"
        points=".2 .2 11.9 .2 2.8 3.6 1 4.1 .2 4.2 .2 .2"
      />
      <polygon fill="#2f51a0" points="12.5 9 13.1 8.3 17.8 7 21.6 9 12.5 9" />
      <polygon fill="#3878bb" points="21.8 5.8 20.9 6.2 21.8 8 21.8 5.8" />
      <polygon
        fill="#52b8e9"
        points="21.8 3.5 21.8 5.9 20.9 6.2 20 4.5 21.8 3.5"
      />
      <polygon
        fill="#91d5ec"
        points="21.8 1.2 21.8 3.5 20 4.5 19.3 3.2 21.8 1.2"
      />
      <polygon
        fill="#5c95b4"
        points="21.8 .2 21.8 1.2 19.3 3.2 19.1 2.8 21.8 .2"
      />
      <polygon fill="#3863ac" points="17.8 7 16.7 6.5 13.5 8.2 17.8 7" />
      <polygon fill="#428ecc" points="20 4.5 16.7 6.5 18 7.1 20.9 6.2 20 4.5" />
      <polygon
        fill="#459fd8"
        points="19.3 3.2 15.9 6.1 16.7 6.5 20 4.5 19.3 3.2"
      />
      <polygon fill="#3766ae" points="20.9 6.2 21.8 8 21.8 9 17.8 7 20.9 6.2" />
      <polygon fill="#4c76a6" points="19.1 2.8 19.3 3.2 13.1 8.3 19.1 2.8" />
      <polygon fill="#149ba8" points=".2 13 3.9 15 6.9 13.9 .2 13" />
      <polygon fill="#005272" points="8.4 14.1 .2 13 9.5 13 8.4 14.1" />
      <polygon fill="#5dc1a8" points=".2 16.5 3.9 15 .2 13 .2 16.5" />
      <polygon
        fill="#129095"
        points="3.9 15 6.2 16.2 8.4 14.1 6.9 13.9 3.9 15"
      />
      <polygon fill="#46286a" points="4.3 21.8 14.3 15.1 13.9 14.7 4.3 21.8" />
      <polygon
        fill="#77308e"
        points="17.7 11.8 18.1 12.6 14.3 15.1 13.8 14.7 17.7 11.8"
      />
      <polygon
        fill="#9c5ba3"
        points="21.8 9 21.8 10 18.1 12.6 17.7 11.8 21.8 9"
      />
      <polygon
        fill="#bc7eb5"
        points="21.8 12.8 18.9 14.1 18.1 12.6 21.8 10 21.8 12.8"
      />
      <polygon
        fill="#7e529f"
        points="18 18.5 20.6 18.1 18.6 13.7 15 15.8 18 18.5"
      />
      <polygon
        fill="#9359a3"
        points="21.8 12.1 21.8 21.8 20.6 18.1 18.7 13.7 21.8 12.1"
      />
      <polygon
        fill="#77469a"
        points="15 15.8 18.7 13.7 18.1 12.6 4.3 21.8 15 15.8"
      />
      <polygon fill="#4a2e84" points="18 18.5 4.3 21.8 21.8 21.8 18 18.5" />
      <polygon fill="#6b4599" points="15 15.8 18 18.5 4.3 21.8 15 15.8" />
      <polygon fill="#ec355e" points=".2 .2 2.8 3.6 1 4.2 .2 4.2 .2 .2" />
      <polygon
        fill="#b2daab"
        points=".2 18.4 2.1 17.3 2.7 19.4 .2 21.8 .2 18.4"
      />
      <polygon
        fill="#66c3a9"
        points=".2 16.4 .2 18.4 2.1 17.3 1.6 15.9 .2 16.4"
      />
      <polygon
        fill="#66c2a2"
        points="5 15.6 2.1 17.3 2.7 19.4 6.2 16.2 5 15.6"
      />
      <polygon fill="#49beae" points="3.9 15 5 15.6 2.1 17.3 1.6 15.9 3.9 15" />
      <polygon fill="#f05b5d" points="1 4.2 .2 1.7 .2 4.5 1 4.2" />
      <polygon
        fill="#3473b6"
        points="16.7 6.5 15.9 6.1 13.1 8.3 13.5 8.2 16.7 6.5"
      />
      <polygon fill="#28418a" points="15.9 6.1 15.7 5.9 13.1 8.3 15.9 6.1" />
      <polygon
        fill="#694a9c"
        points="21.8 21.8 18 18.5 20.5 17.8 21.8 20.7 21.8 21.8"
      />
      <polygon
        fill="#5e4698"
        points="20.5 17.8 21.8 17.6 21.8 20.7 20.5 17.8"
      />
      <polygon
        fill="#9e5fa5"
        points="14.3 15.1 15 15.8 18.7 13.7 18.1 12.6 14.3 15.1"
      />
      <polygon fill="#1ea3a2" points="5 15.6 7.7 14 8.4 14.1 6.2 16.2 5 15.6" />
      <polygon fill="#00687e" points="9.5 13 8.4 14.1 7.7 14 9.2 13 9.5 13" />
      <polygon fill="#224b63" points="6.9 13.9 9 13 9.3 13 7.7 14 6.9 13.9" />
      <polygon
        fill="#7fc9a6"
        points="1.6 15.9 .7 13.3 .2 13 .2 16.4 1.6 15.9"
      />
      <polygon fill="#ee5a55" points="4.6 6 11.9 .2 5.5 7.3 4.6 6" />
      <polygon
        fill="#fff"
        points="11.9 .2 .2 13 9.5 13 .2 21.8 4.3 21.8 21.8 9 12.5 9 21.8 .2 11.9 .2"
      />
    </g>
    <g>
      <path
        fill="#fff"
        d="M39.9,8.8c0,3.3-2.5,4.9-6.1,4.9h-1.7v4.8h-3.6V4.2h5c4,0,6.4,1.5,6.4,4.7Zm-3.7,0c0-1.5-.9-2.3-2.7-2.3h-1.3v4.7h1.4c1.6,0,2.6-.6,2.6-2.4Z"
      />
      <path
        fill="#fff"
        d="M55.1,11.3c0,4.7-2.6,7.5-6.9,7.5s-6.9-2.8-6.9-7.5,2.6-7.5,6.9-7.5,6.9,2.7,6.9,7.5Zm-10,0c0,3.6,1.1,5,3.1,5s3.1-1.4,3.1-5-1.1-5-3.1-5-3.1,1.4-3.1,5Z"
      />
      <path
        fill="#fff"
        d="M71.8,18.5h-4.7l-1.9-10.9-2,10.9h-4.6l-2.5-14.3h3.6l1.5,11.8,2.1-11.8h3.8l2,11.8,1.8-11.8h3.5l-2.6,14.3Z"
      />
      <path
        fill="#fff"
        d="M85.3,6.6h-5.4v3.4h4.7v2.4h-4.7v3.7h5.8v2.4h-9.4V4.2h9.4l-.4,2.4Z"
      />
      <path
        fill="#fff"
        d="M93.2,13h-1.4v5.5h-3.6V4.2h5c4.2,0,6.3,1.4,6.3,4.4s-1,3-2.9,3.9l3.9,6.1h-4.1l-3.1-5.5Zm-1.4-2.3h1.5c1.6,0,2.4-.6,2.4-2.2s-.9-2-2.7-2h-1.3v4.1Z"
      />
      <path
        fill="#fff"
        d="M111.2,5.6l-1,1.1c-1.2-.9-2.2-1.2-3.5-1.2s-2.8,.7-2.8,2.1,.6,1.9,3.4,2.6c2.5,.7,4.5,1.6,4.5,4.3s-2,4.2-5.3,4.2-3.9-.7-5.1-1.8l1.1-1.1c1.1,.9,2.3,1.5,4,1.5s3.4-.9,3.4-2.6-.8-2.1-3.4-2.9c-3-.8-4.5-1.9-4.5-4.1s1.9-3.7,4.7-3.7,3.4,.6,4.6,1.6Z"
      />
      <path
        fill="#fff"
        d="M123.5,5.8h-4.3v12.7h-1.9V5.8h-4.4v-1.5h10.8l-.2,1.5Z"
      />
      <path
        fill="#fff"
        d="M137.5,11.4c0,4.7-2.6,7.3-6.4,7.3s-6.4-2.6-6.4-7.3,2.6-7.4,6.4-7.4,6.4,2.6,6.4,7.4Zm-10.7,0c0,4.1,1.8,5.8,4.3,5.8s4.3-1.7,4.3-5.9-1.7-5.9-4.3-5.9-4.3,1.7-4.3,5.9Z"
      />
      <path
        fill="#fff"
        d="M150.8,5.4l-1,1.2c-1-.7-1.9-1.1-3-1.1-2.4,0-4.4,1.5-4.4,5.9s1.9,5.8,4.3,5.8,2.4-.6,3.3-1.2l1,1.2c-.8,.8-2.3,1.6-4.3,1.6-3.7,0-6.3-2.6-6.3-7.4s2.8-7.4,6.3-7.4,2.9,.5,4.1,1.4Z"
      />
      <path
        fill="#fff"
        d="M155.8,18.5h-1.9V4.3h1.9v14.2Zm2.3-7.7l6.3,7.7h-2.4l-6.2-7.6,5.9-6.6h2.2l-5.9,6.5Z"
      />
    </g>
  </GenIcon>
);

export const ZendarCompleto: IconType = ({ size, color, ...props }) => (
  <GenIcon viewBox="0 0 87.1 23.4" size={size} {...props}>
    <g style={{ fill: color || 'currentcolor' }}>
      <g>
        <path d="M22.6.8H19.2l-1.1,2h0L17.5,4h0L7,23.2h3.4L19.5,6.6A9.3,9.3,0,0,1,20.6,11a9.5,9.5,0,0,1-6.4,8.9l-.9,1.7A10.8,10.8,0,0,0,21.9,11a10.4,10.4,0,0,0-1.7-5.8Z" />
        <path d="M11.9,4.2,4.5,17.8,3.3,16.4,8.4,6.9H5.1l-3.3,6A12.3,12.3,0,0,1,1.6,11a9.6,9.6,0,0,1,9.5-9.5A9.1,9.1,0,0,1,16,2.9l.7-1.2A10.7,10.7,0,0,0,11.1.2,10.8,10.8,0,0,0,.3,11a10.9,10.9,0,0,0,.6,3.6h0a8.8,8.8,0,0,0,.9,2h0l.2.3h.1c0,.1.1.2.2.3h0l.3.4h0A13.1,13.1,0,0,0,5,20H5l.3.2H6l.3.2L7,19.5,15.3,4.2Z" />
      </g>
      <g>
        <path d="M26.9,7.5V6.6H36V9l-5.9,6.2h6.1v2.4H26.6V15.4l5.9-6.5H28.4A1.5,1.5,0,0,1,26.9,7.5Z" />
        <path d="M41.9,17.9c-3.3-.1-5-2.1-5-5.8S38.6,6.5,42,6.5s5.1,2.1,4.9,6.4H39.8c.1,1.7.8,2.5,2.2,2.5a2.4,2.4,0,0,0,1.8-.8,1.3,1.3,0,0,1,.9-.4h2.1C46.2,16.6,44.7,17.9,41.9,17.9Zm-2.1-7H44c-.2-1.4-.9-2.2-2.1-2.2S40,9.5,39.8,10.9Z" />
        <path d="M56.2,17.6c-.9-.1-1.3-.5-1.3-1.4V10.6c-.1-1.2-.7-1.7-2-1.7s-1.8.5-1.8,1.7v7H48.2V11.1c0-3.1,1.6-4.6,4.8-4.6s4.7,1.5,4.7,4.6v6.5Z" />
        <path d="M69.1,4.9v8c0,3.3-1.7,4.9-5.2,4.9s-5.1-1.9-5.1-5.6,1.4-5.6,4.4-5.7a3.3,3.3,0,0,1,3.1,1.7V3.5h1.4A1.4,1.4,0,0,1,69.1,4.9Zm-7.5,7.2q0,3.3,2.4,3.3c1.6,0,2.3-1.1,2.3-3.3S65.5,8.9,64,8.9,61.7,10,61.6,12.1Z" />
        <path d="M73.2,9.5H70.4c.4-2,1.8-3,4.4-3s4.6,1,4.6,3v3.7c0,3.1-1.8,4.4-4.8,4.6s-4.5-1-4.5-3.4,2-3.4,4.8-3.6c1.2-.2,1.8-.5,1.8-1.1s-.7-1-1.8-1S73.4,9,73.2,9.5Zm3.5,3.6v-1a7.1,7.1,0,0,1-2.2.6c-1.1.3-1.7.8-1.7,1.6a1.3,1.3,0,0,0,1.4,1.3A2.3,2.3,0,0,0,76.7,13.1Z" />
        <path d="M80.8,11.9c0-3.5,1.9-5.3,5.6-5.3h.4V9.5h-.9a2.1,2.1,0,0,0-2.3,2.4v5.7H80.8Z" />
      </g>
    </g>
  </GenIcon>
);

export const VendasIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <path d="M20.9,9.5l-1.8,9.4a.9.9,0,0,1-.7.6H4.6a.9.9,0,0,1-.7-.6L2.1,9.5" />
      <g>
        <line x1="9" y1="3.5" x2="4.5" y2="9.5" />
        <line x1="14" y1="3.5" x2="18.5" y2="9.5" />
      </g>
      <line x1="7" y1="12.2" x2="7" y2="16.7" />
      <line x1="11.5" y1="12.2" x2="11.5" y2="16.7" />
      <line x1="16" y1="12.2" x2="16" y2="16.7" />
      <line x1="0.6" y1="9.5" x2="22.4" y2="9.5" />
    </g>
  </GenIcon>
);

export const ContasReceberIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <path d="M21.1,12.3c0-5.1-5.4-7.4-8.1-7.4a18.4,18.4,0,0,0-3.4.4L6.7,3c-.4-.4-.7,0-.7.4V7.6A7.1,7.1,0,0,0,4.3,10H2.4a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5H4.3a13.6,13.6,0,0,0,2.1,2.8A1.9,1.9,0,0,0,6,19a2.1,2.1,0,0,0,2,2.1,2.3,2.3,0,0,0,1.9-1.3,13.2,13.2,0,0,0,2.8.2,9.5,9.5,0,0,0,2.5-.3,2.1,2.1,0,0,0,1.9,1.4A2.1,2.1,0,0,0,19.2,19a1.7,1.7,0,0,0-.5-1.2A7.7,7.7,0,0,0,21.1,12.3Z" />
      <path d="M15.3,3.3A2.5,2.5,0,0,1,17.6,2a2.5,2.5,0,0,1,2.5,2.5,2.4,2.4,0,0,1-.3,1.2" />
      <circle cx="8.5" cy="10.5" r="0.7" />
    </g>
  </GenIcon>
);

export const ContasPagarIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <path d="M13.6,9.3h6a1.2,1.2,0,0,1,1.2,1.1v8.5a1.2,1.2,0,0,1-1.2,1.2H3.4a1.2,1.2,0,0,1-1.2-1.2V10.4A1.2,1.2,0,0,1,3.4,9.3H8.5" />
      <line x1="11.2" y1="16.1" x2="4.6" y2="16.1" />
      <line x1="13.9" y1="18" x2="4.1" y2="18" />
      <path d="M6.4,11.4l-2,3.8s-.4.6-.3.8.8-.1,1.1-.2L8.9,14Z" />
      <path d="M6.4,11.4,16,1.8a.5.5,0,0,1,.7,0l1.8,1.8a.5.5,0,0,1,0,.7L8.9,14" />
      <path d="M18.6,13.1a1.4,1.4,0,0,0-1.5-1.4c-1.5,0-1.6,1.1-1.6,1.4s.1.9,1.4,1.2,2,.5,2,1.8-.8,1.2-1.8,1.2a1.6,1.6,0,0,1-1.9-1.7" />
      <line x1="17" y1="11" x2="17" y2="18.2" />
    </g>
  </GenIcon>
);

export const LojaIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <polyline points="21.7 10.6 21.7 20.9 1.3 20.9 1.3 10.6" />
      <rect x="6.4" y="13.9" width="10.2" height="6.99" />
      <path d="M13.7,7a2.2,2.2,0,0,1-2.2,2.2h0A2.2,2.2,0,0,1,9.3,7" />
      <path d="M6.9,2.1l-2,4V7A2.2,2.2,0,0,1,2.7,9.2h0A2.2,2.2,0,0,1,.5,7V6L3.9,2.1H19.2L22.5,6V7a2.2,2.2,0,0,1-2.2,2.2h0A2.2,2.2,0,0,1,18.1,7" />
      <path d="M10,2.1l-.7,4V7A2.2,2.2,0,0,1,7.1,9.2h0A2.2,2.2,0,0,1,4.9,7" />
      <path d="M16.1,2.1l2,4V7a2.2,2.2,0,0,1-2.2,2.2h0A2.2,2.2,0,0,1,13.7,7V6.1l-.7-4" />
    </g>
  </GenIcon>
);

export const UsuariosIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <path d="M3.7,18.5s-.1-5.1,4.5-7c1.2,1,3.9,2.5,6.6,0,1.8.9,4.2,2.3,4.5,7C15.4,22.2,7.7,22.4,3.7,18.5Z" />
      <path d="M7.2,5.9a4.1,4.1,0,0,1,4.3-4.3,4,4,0,0,1,4.3,4.3c0,2.1-1.7,5.6-4.2,5.6S7.2,7.7,7.2,5.9Z" />
    </g>
  </GenIcon>
);

export const MenuIcon: IconType = ({ size, ...props }) => (
  <GenIcon viewBox="0 0 23 23" size={size} {...props}>
    <g style={Stroke075Rounded}>
      <line x1="2.8" y1="18.6" x2="20.2" y2="18.6" />
      <line x1="2.8" y1="11.5" x2="20.2" y2="11.5" />
      <line x1="2.8" y1="4.4" x2="20.2" y2="4.4" />
    </g>
  </GenIcon>
);
