/* eslint-disable react/require-default-props */
import React from 'react';

interface GenIconInterface extends React.SVGAttributes<SVGElement> {
  path?: string;
  children?: React.ReactNode;
  size?: number | string;
}

const GenIcon = ({ path, size, children, ...props }: GenIconInterface) => {
  return (
    <svg
      height={size || '1em'}
      width={size || '1em'}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {path && !children && <path d={path} />}
      {children && !path && children}
    </svg>
  );
};

export default GenIcon;
