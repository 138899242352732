import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tune, AccessTime } from '@material-ui/icons';
import { BsDownload } from 'react-icons/bs';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  colors,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '5px 10px 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textTitulo: {
    fontWeight: 'bold',
    marginBottom: '-5px',
    display: 'flex',
  },
  textSubtitulo: {
    fontWeight: 'bold',
    marginTop: '4px',
    // bottom: '0',
    marginLeft: '4px',
    fontSize: '12px',
    verticalAlign: 'baseline',
  },
  textAtualizadoEm: {
    fontSize: '12px',
    marginLeft: '5px',
  },
  icon: {
    fontSize: '12px',
    verticalAlign: '-2px',
  },
  titulo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '4px',
  },
  dataSincronizacao: {
    // marginTop: '-25px',
  },
  dialog: {
    background: colors.blueGrey[800],
    color: '#fff',
  },
  containerTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  containerButton: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      color: '#FFFF',
    },
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-end',
      width: '100%',
      marginTop: '8px',
    },
  },
}));

interface Props {
  titulo: string;
  atualizadoEm: string;
  handleOrdem: (ordem: string) => void;
  exibirFiltro: boolean;
  subtitulo?: string;
  appearButtonDownload?: boolean;
  handleDownloadReport?(bol: boolean): void;
  downloadReportDisabled?: boolean;
}

const HeaderCard = (props: Props) => {
  const classes = useStyles();
  const {
    titulo,
    subtitulo,
    atualizadoEm,
    handleOrdem,
    exibirFiltro,
    appearButtonDownload,
    handleDownloadReport,
    downloadReportDisabled,
  } = props;

  const isResponsiveVersion = useMediaQuery('(max-width: 960px)');

  const [open, setOpen] = useState(false);

  const [ordem, setOrdem] = useState('desc');
  const [ordemAplicada, setOrdemAplicada] = useState('desc');

  const handleClickOpen = () => {
    setOpen(true);
    setOrdem(ordemAplicada);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    handleClose();
    setOrdemAplicada(ordem);
    handleOrdem(ordem);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.containerTitle}>
          <div className={classes.titulo}>
            <Typography variant="body1" className={classes.textTitulo}>
              {titulo} <p className={classes.textSubtitulo}>{subtitulo}</p>
            </Typography>
            <div className={classes.dataSincronizacao}>
              <AccessTime className={classes.icon} />
              <span className={classes.textAtualizadoEm}>
                Sincronizado em {atualizadoEm}
              </span>
            </div>
          </div>
          {appearButtonDownload && (
            <Button
              variant={isResponsiveVersion ? 'outlined' : 'text'}
              onClick={() => {
                if (handleDownloadReport) handleDownloadReport(true);
              }}
              className={classes.containerButton}
              disabled={downloadReportDisabled}
            >
              <BsDownload
                size={16}
                style={{
                  margin: '0 8px',
                  lineHeight: '10px',
                  minWidth: '20px',
                }}
              />
              <span
                style={{
                  paddingTop: '4.5px',
                }}
              >
                csv
              </span>
            </Button>
          )}
        </div>
        {exibirFiltro && (
          <IconButton
            style={{ fontSize: '20px' }}
            edge="start"
            onClick={() => handleClickOpen()}
            color="secondary"
          >
            <Tune fontSize="inherit" />
          </IconButton>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle className={classes.dialog} id="responsive-dialog-title">
            Selecione a ordem
          </DialogTitle>
          <DialogContent className={classes.dialog}>
            <RadioGroup
              row
              aria-label="ordenação"
              name="ordenação"
              value={ordem}
              defaultValue="asc"
            >
              <FormControlLabel
                value="desc"
                control={<Radio color="primary" />}
                label="Decrescente"
                labelPlacement="end"
                onClick={() => setOrdem('desc')}
              />
              <FormControlLabel
                value="asc"
                control={<Radio color="primary" />}
                label="Crescente"
                labelPlacement="end"
                onClick={() => setOrdem('asc')}
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions className={classes.dialog}>
            <Button autoFocus onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleOk} color="secondary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default HeaderCard;
