import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
  Badge,
  Button,
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import Transition from 'components/TransitionDefault';
import FormInputSelect from 'components/FormInputSelect';
import auth from 'auth';
import FormInput from 'components/FormInput';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      background: colors.grey[800],
      height: '100%',
    },
    formSearch: {
      height: '100%',
    },
    dialog: {
      background: colors.blueGrey[800],
      color: '#fff',
    },
  })
);

const FiltroUsuario = ({ ...props }) => {
  const { control, reset, actionHandle, getValues } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [quantidadeFiltros, setQuantidadeFiltros] = React.useState(0);
  const [filtroOriginal, setFiltroOriginal] = React.useState(getValues());

  const handleClickOpen = () => {
    setOpen(true);
    setFiltroOriginal(getValues());
  };

  const handleClose = () => {
    setOpen(false);
    reset(filtroOriginal);
  };

  const handleFiltrar = () => {
    actionHandle();
    setOpen(false);

    const { loja, nome, email } = getValues();

    let quantidadeFiltrosAtualizada = 0;

    if (loja) quantidadeFiltrosAtualizada += 1;

    if (nome) quantidadeFiltrosAtualizada += 1;

    if (email) quantidadeFiltrosAtualizada += 1;

    setQuantidadeFiltros(quantidadeFiltrosAtualizada);
  };

  return (
    <>
      <IconButton
        edge="start"
        onClick={() => handleClickOpen()}
        color="secondary"
      >
        <Badge color="secondary" badgeContent={quantidadeFiltros}>
          <Tune fontSize="inherit" />
        </Badge>
      </IconButton>
      <Dialog
        keepMounted
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.body}
      >
        <DialogTitle id="max-width-dialog-title">Filtrar usuários</DialogTitle>
        <DialogContent>
          <div className={classes.formSearch}>
            <FormInputSelect
              autoFocus
              name="loja"
              label="Loja"
              labelWidth={30}
              control={control}
              optionsList={auth.getLojas().map((loja) => {
                return {
                  value: loja.LojaId,
                  description: `${loja.NomeFantasia} (${loja.CidadeUf})`,
                };
              })}
            />
            <FormInput
              fullWidth
              variant="outlined"
              margin="normal"
              label="Nome"
              name="nome"
              control={control}
            />
            <FormInput
              fullWidth
              variant="outlined"
              margin="normal"
              label="E-mail"
              name="email"
              control={control}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleFiltrar} color="primary">
            Filtrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FiltroUsuario;
