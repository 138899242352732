import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    background: (props: any) => props.corPrincipal,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: (props: any) => props.corFundo,
  },
  porcentagem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
  },
  iconCor: {
    height: '14px',
    width: '14px',
    background: (props: any) => props.cor,
    borderRadius: '2px',
  },
  descricao: {
    color: (props: any) => props.cor,
  },
  valor: {
    paddingLeft: '5px',
    fontSize: '12px',
  },
}));

export interface LegendaProps {
  cor: string;
  descricao: string;
  valor: string;
  porcentagem: string;
  corPrincipal: string;
  corFundo: string;
}

const LegendaGrafico = (props: LegendaProps) => {
  const { cor, descricao, valor, porcentagem, corPrincipal, corFundo } = props;
  const classes = useStyles({ cor, corPrincipal, corFundo });

  return (
    <>
      <div className={classes.container}>
        <div
          style={{
            paddingBottom: '5px 0 5px 0',
          }}
        >
          <div className={classes.descricao}>{descricao}</div>
          <div style={{ display: 'flex', marginBottom: '5px' }}>
            <div className={classes.iconCor} />
            <p className={classes.valor}>{valor}</p>
          </div>
        </div>
        <div>
          <div className={classes.porcentagem}>
            <p>{porcentagem}</p>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default LegendaGrafico;
