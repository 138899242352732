import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import TipoSistemaEnum from 'constants/enumTipoSistema';
import auth from 'auth';

const HeaderSistema = () => {
  const { name: nomeSistema, value: valueSistema } = auth.getSistema();

  const sistemaAtual = nomeSistema[0].toUpperCase() + nomeSistema.slice(1);

  let manifest = '';

  switch (valueSistema) {
    case TipoSistemaEnum.ZENDAR:
      manifest = TipoSistemaEnum.properties[1].manifest;
      break;
    case TipoSistemaEnum.POWERSTOCK:
      manifest = TipoSistemaEnum.properties[2].manifest;
      break;
    case TipoSistemaEnum.STI3:
      manifest = TipoSistemaEnum.properties[3].manifest;
      break;

    default:
      manifest = '';
  }
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={sistemaAtual} />

        <title>{sistemaAtual}</title>

        <link rel="manifest" href={manifest} />
      </Helmet>
    </HelmetProvider>
  );
};

export default HeaderSistema;
