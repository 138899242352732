import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import ProgressBar from 'components/ProgressBarVendaPorLoja';
import Totalizador from 'components/Totalizador';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import DatePickerRange from 'components/DatePickerRange';
import dateToDayDatePicker from 'helpers/dateFormat';
import { DayRange } from 'react-modern-calendar-datepicker';
import { maxHourDate, minHourDate } from 'helpers/dateMinMax';

const VendaPorLoja = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [progressBarList, setProgressBarList] = useState([] as any);
  const [valorTotal, setValorTotal] = useState(0);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao === IdentificacaoGrafico.TodasEmpresas_VendasPorLoja
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const calcularPorcentagens = (list, total) => {
    const listPorcentagem = list.map((obj, index) => ({
      ...obj,
      id: index,
      porcentagem: obj.valor === 0 ? 0 : obj.valor / (total / 100),
    }));

    setProgressBarList(
      listPorcentagem.sort(
        (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
      )
    );
  };

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      const informacao = obterDadosGrafico(chartData);

      const informacoesFiltro = informacao.dados.filter((item) => {
        const dataItem = new Date(item.Data);

        return (
          dtInicial &&
          dtFinal &&
          dataItem >= minHourDate(dtInicial) &&
          dataItem <= maxHourDate(dtFinal)
        );
      });

      let valTotal = 0;
      const listItens = Array<any>();

      for (let index = 0; index < informacoesFiltro.length; index += 1) {
        const infoFiltro = informacoesFiltro[index];
        valTotal += infoFiltro.ValorTotal;
        for (
          let indexDado = 0;
          indexDado < infoFiltro.Dados.length;
          indexDado += 1
        ) {
          const dado = infoFiltro.Dados[indexDado];

          const objIndex = listItens.findIndex(
            (obj) => obj.lojaIdExterno === dado.LojaIdExterno
          );

          if (objIndex === -1) {
            listItens.push({
              id: listItens.length - 1,
              nome: dado.NomeFantasia,
              cidade: dado.CidadeUf,
              valor: dado.Sincronizado ? dado.ValorTotal : -1,
              sincronizado: dado.Sincronizado,
              lojaIdExterno: dado.LojaIdExterno,
            });
          } else {
            const itemUpdate = listItens[objIndex];

            listItens[objIndex] = {
              id: itemUpdate.id,
              nome: itemUpdate.nome,
              cidade: itemUpdate.cidade,
              valor: itemUpdate.valor + dado.ValorTotal,
              sincronizado: itemUpdate.sincronizado,
              lojaIdExterno: itemUpdate.lojaIdExterno,
            };
          }
        }
      }

      setValorTotal(valTotal);
      calcularPorcentagens(listItens, valTotal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const handleOrdem = (ordem: string) => {
    const list =
      ordem === 'desc'
        ? progressBarList.sort(
            (a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem)
          )
        : progressBarList.sort(
            (a, b) => parseFloat(a.porcentagem) - parseFloat(b.porcentagem)
          );

    setProgressBarList([...list]);
  };

  const resetGrafico = () => {
    setProgressBarList([]);
    setValorTotal(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);
      // dateFrom.setMonth(dateFrom.getMonth() - 3);

      setDataUltimaSincronizacao(dateTo.toLocaleString());

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item) => {
        return {
          texto: item.Data,
          valor: item.Data,
          possuiDados: item.ValorTotal > 0,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
  };

  return (
    <div style={{ paddingBottom: '5px' }}>
      <HeaderCard
        titulo="Por Lojas"
        atualizadoEm={dataUltimaSincronizacao}
        handleOrdem={handleOrdem}
        exibirFiltro={false} // TODO rever se é necessário
      />

      <DatePickerRange
        bgButtonColor="#0B99C1"
        bgDateColor="#0B597D"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador valor={valorTotal} titulo="Total das Vendas" />

      {progressBarList?.length === 0 && <GraficoSemDados />}

      {progressBarList &&
        progressBarList.map((item) => (
          <ProgressBar
            key={`${item.id}`}
            bgcolor="#0B597D"
            valor={item.valor}
            nome={item.nome}
            cidade={item.cidade}
            sincronizado={item.sincronizado}
            completed={item.porcentagem}
          />
        ))}
    </div>
  );
};

export default VendaPorLoja;
