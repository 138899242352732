import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import RadioButtonFiltros from 'components/RadioButtonFiltros';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import TotalizadorDividido from 'components/TotalizadorDividido';
import Totalizador from 'components/Totalizador';

import formatarDinheiro from 'helpers/moneyFormat';
import { Divider, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    background: '#993A50',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  observacao: {
    padding: '0px 10px 5px 10px',
    fontSize: '12px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: '#FE6383',
  },
  porcentagem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
  },
  descricao: {
    color: (props: any) => props.cor,
    fontSize: '14px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  obs: {
    color: (props: any) => props.cor,
    fontSize: '12px',
    paddingBottom: '5px 0 5px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  quantidade: {
    fontSize: '12px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  valorTotal: {
    fontSize: '12px',
    marginBottom: '5px',
    minWidth: '80px',
    textAlign: 'right',
  },
}));

const ProdutoMaisVendidos = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [radioButtonList, setRadioButtonList] = useState([{}] as any);
  const [totalVendas, setTotalVendas] = useState(0);
  const [totalVendasAdicional, setTotalVendasAdicional] = useState(0);

  const [legenda, setLegenda] = useState([] as Array<any>);

  const obterDadosGrafico = (dados) => {
    const informacoesFiltro = dados.filter(
      (item) =>
        item.identificacao ===
        IdentificacaoGrafico.Produtos_ProdutosMaisVendidos
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  };

  const obterDadosPorFiltro = useCallback(
    (valor) => {
      const informacao = obterDadosGrafico(chartData);

      const informacaoFiltro = informacao.dados.find(
        (item) => item.Filtro.Valor === valor
      );

      const items = informacaoFiltro.Dados.map((item, index) => {
        return {
          id: index,
          descricao: item.Descricao,
          obs: item.InformacaoAdicional[0].Descricao,
          valorTotal: item.InformacaoAdicional[0].Total,
          quantidade: item.Total,
        };
      });

      setTotalVendas(informacaoFiltro.Total);
      setTotalVendasAdicional(informacaoFiltro.TotalAdicional);

      setLegenda(items);
    },
    [chartData]
  );

  const resetGrafico = () => {
    setRadioButtonList([{}]);
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);
    if (informacao) {
      setDataUltimaSincronizacao(
        new Date(informacao.dataUltimaSincronizacao).toLocaleString()
      );
      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Filtro.Descricao,
          valor: item.Filtro.Valor,
          possuiDados: item.Total > 0,
          key: index,
        };
      });
      if (filtros) {
        setRadioButtonList(filtros);
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(
            filtrosComValor[filtrosComValor.length - 1].valor
          );
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro]);

  const classes = useStyles({ cor: 'white' });

  const InfoGrafico = (props) => {
    const { descricao, obs, valorTotal, quantidade } = props;

    return (
      <>
        <div className={classes.container}>
          <div className={classes.descricao}>
            <div>{descricao}</div>
            <div className={classes.quantidade}>
              <p>{quantidade}</p>
            </div>
          </div>
          <div
            style={{
              paddingBottom: '5px 0 5px 0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className={classes.obs}>{obs}</div>
            <div className={classes.valorTotal}>
              {formatarDinheiro(valorTotal, true)}
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
      </>
    );
  };
  return (
    <>
      <HeaderCard
        titulo="Produtos Mais Vendidos"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />
      <RadioButtonFiltros
        listItems={radioButtonList}
        bgSelecionado="#FE6383"
        bgNaoSelecionado="#993A50"
        handle={obterDadosPorFiltro}
      />
      {
        // TODO Rever regra após atualização dos clientes
        totalVendasAdicional ? (
          <>
            <TotalizadorDividido
              valorEsquerdo={totalVendas}
              tituloEsquerdo="Quantidade Total"
              exibirEmReaisEsquerdo={false}
              valorDireito={totalVendasAdicional}
              tituloDireito="Valor Total"
              exibirEmReaisDireito
            />
            <Typography className={classes.observacao}>
              Top 50 produtos mais vendidos.
            </Typography>
          </>
        ) : (
          <>
            <Totalizador
              valor={totalVendas}
              titulo="Total de Itens Vendidos"
              exibirEmReais={false}
            />
            <Typography className={classes.observacao}>
              Top 50 produtos mais vendidos.
            </Typography>
          </>
        )
      }

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            {legenda.map((item) => (
              <InfoGrafico
                key={item.id}
                descricao={item.descricao}
                valorTotal={item.valorTotal}
                obs={item.obs}
                quantidade={item.quantidade}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProdutoMaisVendidos;
