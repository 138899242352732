import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

function usePersistedState<T>(key: string, initialState: T): Response<T> {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    const openDB = indexedDB.open('dashboard', 1);

    openDB.onupgradeneeded = () => {
      const db = openDB.result;
      if (!db.objectStoreNames.contains('store')) {
        db.createObjectStore('store');
      }
    };

    openDB.onsuccess = () => {
      const db = openDB.result;
      const tx = db.transaction('store', 'readonly');
      const store = tx.objectStore('store');
      const request = store.get(key);

      request.onsuccess = () => {
        const storedValue = request.result;
        if (storedValue !== undefined) {
          setState(storedValue);
        }
      };

      tx.oncomplete = () => {
        db.close();
      };
    };
  }, [key]);

  useEffect(() => {
    const openDB = indexedDB.open('dashboard', 1);

    openDB.onsuccess = () => {
      const db = openDB.result;
      const tx = db.transaction('store', 'readwrite');
      const store = tx.objectStore('store');
      store.put(state, key);

      tx.oncomplete = () => {
        db.close();
      };
    };
  }, [key, state]);

  return [state, setState];
}

export default usePersistedState;
