import React, { useState, useEffect, useCallback } from 'react';
import HeaderCard from 'components/HeaderCard';
import { useChartData } from 'store/ChartDataContext';
import IdentificacaoGrafico from 'constants/identificacaoGrafico';
import GraficoSemDados from 'components/GraficoSemDados';
import Totalizador from 'components/Totalizador';
import formatarDinheiro from 'helpers/moneyFormat';
import { Divider, makeStyles } from '@material-ui/core';
import DatePickerRange from 'components/DatePickerRange';
import dateToDayDatePicker from 'helpers/dateFormat';
import { DayRange } from 'react-modern-calendar-datepicker';
import { maxHourDate, minHourDate } from 'helpers/dateMinMax';

const useStyles = makeStyles(() => ({
  container: {
    background: '#18826B',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 10px 0px 10px',
    margin: '0px 10px 0px 10px',
  },
  divider: {
    margin: '0px 10px 0px 10px',
    background: '#28D8B2',
  },
  porcentagem: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: 'bold',
  },
  descricao: {
    color: (props: any) => props.cor,
  },
  valor: {
    fontSize: '12px',
  },
}));

const VendaTotalizadores = () => {
  const { chartData } = useChartData();
  const [dataUltimaSincronizacao, setDataUltimaSincronizacao] = useState('');
  const [totalVendas, setTotalVendas] = useState(0);
  const [legenda, setLegenda] = useState([] as Array<any>);
  const [defaultDateRange, setDefaultDateRange] = useState<DayRange>({
    from: dateToDayDatePicker(new Date()),
    to: dateToDayDatePicker(new Date()),
  });

  const obterDadosGrafico = useCallback((dados) => {
    const informacoesFiltro = dados.filter(
      (item) => item.identificacao === IdentificacaoGrafico.Vendas_Totalizadores
    );

    if (informacoesFiltro.length > 0) {
      return {
        ...informacoesFiltro[0],
      };
    }

    return null;
  }, []);

  const obterDadosPorFiltro = useCallback(
    (dtInicial, dtFinal) => {
      const informacao = obterDadosGrafico(chartData);

      const informacoesFiltro = informacao.dados.filter((item) => {
        const dataItem = new Date(item.Data);
        return (
          dtInicial &&
          dtFinal &&
          dataItem >= minHourDate(dtInicial) &&
          dataItem <= maxHourDate(dtFinal)
        );
      });

      let valTotal = 0;
      const listItens = Array<any>();

      for (let index = 0; index < informacoesFiltro.length; index += 1) {
        const infoFiltro = informacoesFiltro[index];
        valTotal += infoFiltro.Total;

        for (
          let indexDado = 0;
          indexDado < infoFiltro.Dados.length;
          indexDado += 1
        ) {
          const dado = infoFiltro.Dados[indexDado];

          const objIndex = listItens.findIndex(
            (obj) =>
              obj.descricao === dado.Descricao &&
              obj.obs === dado.InformacaoAdicional[0].Descricao
          );

          if (objIndex === -1) {
            listItens.push({
              id: listItens.length - 1,
              descricao: dado.Descricao,
              obs: dado.InformacaoAdicional[0].Descricao,
              valor: dado.Total,
            });
          } else {
            const itemUpdate = listItens[objIndex];

            listItens[objIndex] = {
              id: itemUpdate.id,
              descricao: dado.Descricao,
              obs: dado.InformacaoAdicional[0].Descricao,
              valor: itemUpdate.valor + dado.Total,
            };
          }
        }
      }

      setTotalVendas(valTotal);
      setLegenda(listItens);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const resetGrafico = () => {
    setTotalVendas(0);
  };

  useEffect(() => {
    const informacao = obterDadosGrafico(chartData);

    if (informacao) {
      const dateTo = new Date(informacao.dataUltimaSincronizacao);
      const dateFrom = new Date(informacao.dataUltimaSincronizacao);

      setDataUltimaSincronizacao(dateTo.toLocaleString());

      setDefaultDateRange({
        from: dateToDayDatePicker(dateFrom),
        to: dateToDayDatePicker(dateTo),
      });

      const filtros = informacao.dados.map((item, index) => {
        return {
          texto: item.Data,
          valor: item.Total,
          possuiDados: item.Total > 0,
          key: index,
        };
      });

      if (filtros) {
        const filtrosComValor = filtros.filter((filtro) => filtro.possuiDados);
        if (filtrosComValor.length > 0) {
          obterDadosPorFiltro(dateFrom, dateTo);
        } else {
          resetGrafico();
        }
      }
    } else {
      resetGrafico();
    }
  }, [chartData, obterDadosPorFiltro, obterDadosGrafico]);

  const InfoGrafico = (props) => {
    const { descricao, valor, obs } = props;
    const classes = useStyles({ cor: 'white' });

    return (
      <>
        <div className={classes.container}>
          <div
            style={{
              paddingBottom: '5px 0 5px 0',
            }}
          >
            <div className={classes.descricao}>{descricao}</div>
            <div style={{ display: 'flex', marginBottom: '5px' }}>
              <p className={classes.valor}>{obs}</p>
            </div>
          </div>
          <div>
            <div className={classes.porcentagem}>
              <p>{formatarDinheiro(valor, true)}</p>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
      </>
    );
  };

  const dateOnChange = (selectedDate: { from: Date; to: Date }) => {
    obterDadosPorFiltro(selectedDate.from, selectedDate.to);
  };

  return (
    <>
      <HeaderCard
        titulo="Totalizadores de Vendas"
        atualizadoEm={dataUltimaSincronizacao}
        exibirFiltro={false}
        handleOrdem={() => {}}
      />

      <DatePickerRange
        bgButtonColor="#28D8B2"
        bgDateColor="#18826B"
        onChange={dateOnChange}
        defaultDateRange={defaultDateRange}
      />

      <Totalizador
        valor={totalVendas}
        titulo="Total das Vendas"
        exibirEmReais
      />

      <div style={{ marginBottom: '4px' }}>
        {totalVendas === 0 && <GraficoSemDados />}
        {totalVendas > 0 && (
          <div style={{ paddingBottom: '5px' }}>
            {legenda.map((item) => (
              <InfoGrafico
                key={item.id}
                descricao={item.descricao}
                valor={item.valor}
                obs={item.obs}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default VendaTotalizadores;
