import { maxHourDate, minHourDate } from './dateMinMax';

export default function extractInformationChartV2(
  informacao: any,
  dtInicial: any,
  dtFinal: any
) {
  const informacoesFiltro = informacao.dados.filter((item) => {
    const dataItem = new Date(item.Data);
    return (
      dtInicial &&
      dtFinal &&
      dataItem >= minHourDate(dtInicial) &&
      dataItem <= maxHourDate(dtFinal)
    );
  });

  let valTotal = 0;
  const listItens = Array<any>();

  for (let index = 0; index < informacoesFiltro.length; index += 1) {
    const infoFiltro = informacoesFiltro[index];
    valTotal += infoFiltro.Total;

    for (
      let indexDado = 0;
      indexDado < infoFiltro.Dados.length;
      indexDado += 1
    ) {
      const dado = infoFiltro.Dados[indexDado];

      const objIndex = listItens.findIndex(
        (obj) => obj.nome === dado.Descricao
      );

      if (objIndex === -1) {
        listItens.push({
          id: listItens.length - 1,
          nome: dado.Descricao,
          valor: dado.Total,
        });
      } else {
        const itemUpdate = listItens[objIndex];

        listItens[objIndex] = {
          id: itemUpdate.id,
          nome: itemUpdate.nome,
          valor: itemUpdate.valor + dado.Total,
        };
      }
    }
  }
  return { valTotal, listItens };
}
