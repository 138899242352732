const IdentificacaoGrafico = {
  TodasEmpresas_VendasPorLoja: 0,
  TodasEmpresas_VendasPorFormaPagto: 1,
  TodasEmpresas_VendasPorOrigem: 2,
  Vendas_VendasPorVendedor: 3,
  Vendas_VendasPorHora: 4,
  Vendas_VendasPorDiaDaSemana: 5,
  Vendas_VendasPorFormaPagto: 6,
  Vendas_TicketMedio: 7,
  Vendas_Totalizadores: 8,
  Produtos_SituacaoProdutos: 9,
  Produtos_ProdutosMaisVendidos: 10,
  Produtos_ProdutosEmEstoque: 11,
  ContasAPagar_ContasAPagar: 12,
  ContasAPagar_ContasPagas: 13,
  ContasAReceber_ContasAReceber: 14,
  ContasAReceber_ContasRecebidas: 15,
  Produtos_TotalGruposVendidos: 16,
};

export default IdentificacaoGrafico;
